import { Button } from '@chakra-ui/button'
import { useColorModeValue } from '@chakra-ui/color-mode'
import { FormControl, FormLabel } from '@chakra-ui/form-control'
import { AddIcon } from '@chakra-ui/icons'
import { Flex, HStack, Text, VStack } from '@chakra-ui/layout'
import { Timestamp } from '@firebase/firestore'
import { useTranslation } from 'react-i18next'
import { FaGift } from 'react-icons/fa'

import { uuid } from 'lib/uuid'
import { GiftDate } from 'screens/onboard/GiftDate'
import { SpecialDate } from 'types'

import { AnimatedList } from './Animator/AnimatedList'

type Props = {
  dates: Array<SpecialDate>
  setFieldValue: (field: string, value: unknown) => void
}

export const FormControlDates = ({
  dates,
  setFieldValue,
}: Props): JSX.Element => {
  const { t } = useTranslation()

  const handleAdd = () => {
    setFieldValue('dates', [
      ...dates,
      {
        date: Timestamp.now(),
        id: uuid(),
        name: '',
      },
    ])
  }

  const handleRemove = (id: string) => {
    setFieldValue(
      'dates',
      dates.filter((date) => date.id !== id),
    )
  }

  return (
    <FormControl id="dates">
      <Flex justifyContent="space-between" w="100%">
        <HStack
          as={FormLabel}
          color={useColorModeValue('primary.600', 'primary.100')}
        >
          <FaGift />
          <Text>{t('dates')}</Text>
        </HStack>
        <Button
          colorScheme="primary"
          onClick={handleAdd}
          rightIcon={<AddIcon />}
          size="xs"
        >
          {t('add')}
        </Button>
      </Flex>
      <VStack>
        <AnimatedList items={dates}>
          {(date, index) => {
            const { id } = date as SpecialDate

            return (
              <GiftDate
                index={index}
                key={id}
                onRemove={handleRemove}
                setFieldValue={setFieldValue}
                showGiftIcon={false}
                specialDate={date as SpecialDate}
              />
            )
          }}
        </AnimatedList>
      </VStack>
    </FormControl>
  )
}
