import { Text } from '@chakra-ui/layout'

import { Translate } from 'components/Translate'
import { Update } from 'types'

import { FriendUpdateWrapper } from './Wrapper'

type Meta = {
  photoURL: string
  userDisplayName: string
  wishId: string
  wishName: string
}

export const FriendUpdateWishOwn = ({
  update,
}: {
  update: Update
}): JSX.Element => {
  const { userId } = update
  const meta = update.meta as Meta

  const links = [
    { href: `/friends/${userId}`, label: meta.userDisplayName },
    {
      href: `/friends/${userId}/wishes/${meta.wishId}`,
      label: meta.wishName,
    },
  ]

  return (
    <FriendUpdateWrapper links={links} update={update}>
      <Text fontSize={['xs', 'md']} lineHeight="1.5em">
        <Translate
          i18nKey="profile.friend_updates.wish_own"
          values={{
            name: meta.userDisplayName,
            wish: meta.wishName,
          }}
        />
      </Text>
    </FriendUpdateWrapper>
  )
}
