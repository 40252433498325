import { CheckIcon } from '@chakra-ui/icons'
import { Tag } from '@chakra-ui/tag'
import { Tooltip } from '@chakra-ui/tooltip'
import { useTranslation } from 'react-i18next'

export const TagWishFullfilled = (): JSX.Element => {
  const { t } = useTranslation()

  return (
    <Tooltip hasArrow label={t('tag_wish_fulfilled.tooltip')}>
      <Tag colorScheme="green">
        <CheckIcon />
      </Tag>
    </Tooltip>
  )
}
