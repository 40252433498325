import {
  Box,
  Button,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  IconButton,
  Image,
  Tooltip,
  useBoolean,
} from '@chakra-ui/react'
import { MouseEvent, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { BiMove } from 'react-icons/bi'

import { Coordinates } from 'types'

type Props = {
  photoURL: string
  onSetPosition: (position: { x: number; y: number }) => void
  defaultPosition: Coordinates
  disabled?: boolean
}

export const ImagePositionEditor = ({
  photoURL,
  onSetPosition,
  defaultPosition,
  disabled,
}: Props): JSX.Element => {
  const { t } = useTranslation()
  const [isOpen, { on, off }] = useBoolean(false)
  const [position, setPosition] = useState(defaultPosition)

  const handleMove = (e: MouseEvent<HTMLImageElement>) => {
    const { left, top, width, height } = (
      e.target as HTMLImageElement
    ).getBoundingClientRect()

    const x = (100 * (e.clientX - left)) / width
    const y = (100 * (e.clientY - top)) / height

    setPosition({ x, y })
  }

  const handleSave = () => {
    onSetPosition(position)
    off()
  }

  return (
    <>
      <Tooltip label={t('image_position_editor.tooltip')}>
        <IconButton
          aria-label={t('image_position_editor.tooltip')}
          disabled={disabled}
          icon={<BiMove />}
          onClick={on}
          variant="secondary"
        ></IconButton>
      </Tooltip>
      <Drawer isOpen={isOpen} onClose={off} placement="right">
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader>{t('image_position_editor.title')}</DrawerHeader>
          <DrawerBody>
            <Box position="relative">
              <Image
                borderRadius={8}
                draggable={false}
                onMouseDown={handleMove}
                src={photoURL}
                width="100%"
              />
              <Box
                bg="secondary.300"
                h={0.5}
                position="absolute"
                top={`${position.y}%`}
                w="100%"
              ></Box>
              <Box
                bg="secondary.300"
                h="100%"
                left={`${position.x}%`}
                position="absolute"
                top={0}
                w={0.5}
              ></Box>
            </Box>
          </DrawerBody>
          <DrawerFooter>
            <Button onClick={handleSave}>
              {t('image_position_editor.save')}
            </Button>
          </DrawerFooter>
        </DrawerContent>
      </Drawer>
    </>
  )
}
