import { Button } from '@chakra-ui/button'
import { useColorModeValue } from '@chakra-ui/color-mode'
import { useDisclosure } from '@chakra-ui/hooks'
import { VStack, Wrap } from '@chakra-ui/layout'
import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay
} from '@chakra-ui/modal'
import {
  FormControl,
  FormHelperText,
  FormLabel,
  Input,
  Text
} from '@chakra-ui/react'
import { AnimatePresence, motion, Variants } from 'framer-motion'
import { ChangeEvent, useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { useLoggedUser } from 'hooks/useLoggedUser'
import { userIndex } from 'services/algolia'
import { User } from 'types'

import { UserCard } from './UserCard'

const noUserVariants: Variants = {
  hidden: {
    maxHeight: 0,
    opacity: 0,
  },
  visible: {
    maxHeight: 100,
    opacity: 1,
    transition: { duration: 3 },
  },
}

const variants: Variants = {
  hidden: {
    maxHeight: [118, 0],
    opacity: 0,
  },
  visible: {
    maxHeight: [0, 118],
    opacity: [0, 0, 1],
  },
}

export const ModalSearchFriend = (): JSX.Element => {
  const { t } = useTranslation()
  const { user } = useLoggedUser()
  const [searchTerm, setSearchTerm] = useState('')
  const [searchResult, setSearchResult] = useState<Array<User> | null>([])
  const { isOpen, onOpen, onClose } = useDisclosure()

  const getSearchResult = useCallback(
    async (name: string): Promise<Array<User>> => {
      if (name.length < 2) return []

      const searchResults = await userIndex.search<User>(name)

      return searchResults.hits.map((hit) => ({ ...hit, id: hit.objectID }))
    },
    [],
  )

  const handleChange = async (event: ChangeEvent<HTMLInputElement>) => {
    const term = event.target.value
    setSearchTerm(term)

    const searchResult = await getSearchResult(term)

    setSearchResult(searchResult)
  }

  const potentialFriends = searchResult?.filter(
    (u) => !user?.friendsIds?.includes(u.id),
  )
  const hasResults = Boolean(potentialFriends?.length)

  return (
    <>
      <Button onClick={onOpen}>{t('modal_add_friend.open')}</Button>

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader
            color={useColorModeValue('primary.400', 'primary.200')}
            fontFamily="heading"
            fontWeight={100}
          >
            {t('modal_add_friend.title')}
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <VStack mt={4} spacing={6}>
              <FormControl id="name">
                <FormLabel>{t('modal_add_friend.label')}</FormLabel>
                <Input
                  autoComplete="off"
                  onChange={handleChange}
                  type="text"
                  value={searchTerm}
                />
                <FormHelperText>{t('modal_add_friend.helper')}</FormHelperText>
              </FormControl>
            </VStack>
          </ModalBody>
          <ModalFooter justifyContent="flex-start" py={6}>
            <VStack w="100%">
              <Wrap w="100%">
                <AnimatePresence>
                  {potentialFriends?.map((potentialFriend: User) => (
                    <motion.article
                      animate="visible"
                      exit="hidden"
                      initial="hidden"
                      key={potentialFriend.id}
                      style={{ flex: 1 }}
                      variants={variants}
                    >
                      <UserCard user={potentialFriend} />
                    </motion.article>
                  ))}
                </AnimatePresence>
              </Wrap>
              <Text
                animate={
                  !hasResults && searchTerm.length > 1 ? 'visible' : 'hidden'
                }
                as={motion.p}
                color="secondary.300"
                overflow="hidden"
                textAlign="right"
                variants={noUserVariants}
                w="100%"
              >
                {t('modal_add_friend.error')}
              </Text>
            </VStack>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}
