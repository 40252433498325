import { Spinner } from '@chakra-ui/spinner'
import { Center } from '@chakra-ui/layout'
import { Fade } from '@chakra-ui/transition'

type Props = {
  visible: boolean
}

export const FullScreenLoader = ({ visible }: Props): JSX.Element => {
  return (
    <Fade in={visible}>
      <Center
        background="gray.900"
        h="100vh"
        pointerEvents={visible ? 'all' : 'none'}
        position="fixed"
        w="100vw"
        zIndex={10}
      >
        <Spinner color="primary.700" size="xl" />
      </Center>
    </Fade>
  )
}
