import { Timestamp } from '@firebase/firestore'

import { Notification, NotificationState, NotificationType } from 'types'

import { uuid } from './uuid'

export const generateNotification = (
  target: string,
  type: NotificationType,
  meta: Record<string, string>,
): Notification => {
  return {
    date: Timestamp.now(),
    id: uuid(),
    meta,
    state: NotificationState.NEW,
    type,
    userId: target,
  }
}
