import { useTranslation } from 'react-i18next'

import { DeferredLink } from 'components/DeferredLink'
import { Logo } from 'components/Logo'
import { NavLink } from 'components/NavLink'

export const StickyMenu = (): JSX.Element => {
  const { t } = useTranslation()

  return (
    <>
      <DeferredLink to="/profile">
        <Logo />
      </DeferredLink>
      <NavLink to="/profile">{t('navbar.home')}</NavLink>
      <NavLink to="/friends">{t('navbar.friends')}</NavLink>
      <NavLink to="/wishes">{t('navbar.wishes')}</NavLink>
    </>
  )
}
