import { Button } from '@chakra-ui/button'
import { Box, Flex, Heading, HStack, Text, VStack } from '@chakra-ui/layout'
import { Spinner } from '@chakra-ui/spinner'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { FaGift, FaUserAlt } from 'react-icons/fa'
import { FcGoogle } from 'react-icons/fc'
import { Redirect } from 'react-router-dom'

import { Translate } from 'components/Translate'
import { Logo } from 'components/Logo'
import { useLoggedUser } from 'hooks/useLoggedUser'

type Props = {
  onLogin: () => Promise<void>
}

export const Login = ({ onLogin }: Props): JSX.Element => {
  const [loading, setLoading] = useState(false)
  const { user } = useLoggedUser()
  const { t } = useTranslation()

  const boxBgColor = 'gray.700'

  const handleLogin = async () => {
    setLoading(true)
    await onLogin()
    setLoading(false)
  }

  if (user) {
    return <Redirect to={user.isNew ? '/onboard' : '/profile'} />
  }

  return (
    <Flex direction="column" marginX="auto" maxW="1200px" minH="100vh">
      <HStack justifyContent="end" p={4}>
        <Logo />
      </HStack>
      <VStack flex={1} p={4} pt={10}>
        <Heading
          alignSelf="end"
          fontSize={[40, 60, 90]}
          maxW={900}
          textAlign="right"
        >
          <Translate i18nKey="login.hero" />
        </Heading>

        <FaGift size={20} />

        <Text
          fontSize={[16, 20]}
          lineHeight={[6, 10]}
          maxW={700}
          py={[12, 8]}
          textAlign="center"
        >
          <Translate i18nKey="login.description" />
        </Text>
      </VStack>
      <Box
        alignSelf="center"
        bg={boxBgColor}
        borderRadius="xl"
        boxSizing="border-box"
        m={4}
        maxW="calc(100% - 32px)"
        mt={6}
        p={6}
        width={400}
      >
        <HStack spacing={3}>
          <FaUserAlt />
          <Text>{t('login.account.title')}</Text>
        </HStack>
        <VStack pt={4}>
          {loading ? (
            <Spinner />
          ) : (
            <Button
              leftIcon={<FcGoogle />}
              onClick={handleLogin}
              variant={'outline'}
              w={'full'}
            >
              <Text w="100%">
                {t('login.account.provider', { provider: 'Google' })}
              </Text>
            </Button>
          )}
        </VStack>
      </Box>
    </Flex>
  )
}
