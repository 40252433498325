import {
  Avatar,
  Heading,
  HStack,
  Skeleton,
  SkeletonCircle,
  Text,
  VStack
} from '@chakra-ui/react'

import { User } from 'types'

import { AnimatedCard } from './Animator/AnimatedCard'

type Props = {
  user?: User
  isLoading?: boolean
}

export const CardFriend = ({ user, isLoading = false }: Props): JSX.Element => {
  return (
    <AnimatedCard id={user?.id} p={[4, 4, 6]} to={`/friends/${user?.id}`}>
      <HStack spacing={4}>
        <SkeletonCircle isLoaded={!isLoading} size="16">
          <Avatar name="Avatar Alt" size="lg" src={user?.photoURL} />
        </SkeletonCircle>
        <VStack alignItems="start">
          <Skeleton isLoaded={!isLoading} size="6">
            <Heading fontSize="xl" textAlign="left" w="100%">
              {user?.displayName || 'John Doe'}
            </Heading>
          </Skeleton>
          <Skeleton isLoaded={!isLoading} mt={4} size="2">
            <Text color="gray.500" fontWeight={600}>
              {user?.nickName || '@John Doe'}
            </Text>
          </Skeleton>
        </VStack>
      </HStack>
    </AnimatedCard>
  )
}
