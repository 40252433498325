import { Avatar } from '@chakra-ui/avatar'
import { Button } from '@chakra-ui/button'
import { useColorModeValue } from '@chakra-ui/color-mode'
import { Box, HStack, Text, VStack } from '@chakra-ui/layout'
import { Skeleton, SkeletonCircle } from '@chakra-ui/react'
import { Tooltip } from '@chakra-ui/tooltip'
import { Timestamp } from '@firebase/firestore'
import { useTranslation } from 'react-i18next'

import { SpecialDateWithUsers } from 'types'

type Props = {
  specialDate?: SpecialDateWithUsers
  isLoading: boolean
  onClick?: (specialDate: SpecialDateWithUsers) => void
}

const defaultValues = {
  date: Timestamp.now(),
  name: 'date name',
  users: [],
}

export const IncomingDateButton = ({
  specialDate,
  isLoading,
  onClick,
}: Props): JSX.Element => {
  const { t } = useTranslation()
  const { name, date, users } = specialDate || defaultValues

  const handleClick = () => {
    if (onClick && specialDate) onClick(specialDate)
  }

  return (
    <HStack
      as={Button}
      borderRadius="md"
      boxShadow={['base', 'base', 'lg', 'xl']}
      colorScheme={useColorModeValue('primary', 'secondary')}
      h={16}
      onClick={handleClick}
      p={2}
      px={4}
      spacing={4}
      w="100%"
    >
      <Tooltip label={users.map(({ displayName }) => displayName).join(', ')}>
        <Box h="100%" position="relative">
          {
            <SkeletonCircle isLoaded={!isLoading} size="48px">
              {users.slice(0, 3).map((user, index) => (
                <Avatar
                  key={user.id}
                  name={user.displayName}
                  position={index === 0 ? 'relative' : 'absolute'}
                  right={index * 2}
                  shadow="md"
                  src={user.photoURL}
                  top={0}
                />
              ))}
            </SkeletonCircle>
          }
        </Box>
      </Tooltip>
      <VStack alignItems="start" w="100%">
        <Skeleton isLoaded={!isLoading}>
          <Text fontSize="md" fontWeight="semibold">
            {name}
          </Text>
        </Skeleton>
        <Skeleton fontWeight="hairline" isLoaded={!isLoading}>
          <Text>{t('date.day', { date })}</Text>
        </Skeleton>
      </VStack>
    </HStack>
  )
}
