import {
  Box,
  Heading,
  HStack,
  Image,
  Skeleton,
  Text,
  VStack,
} from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'

import { useLoggedUser } from 'hooks/useLoggedUser'
import { setDefaultImage } from 'lib/html'
import { coordinatesToStyle } from 'lib/style'
import { Coordinates, Wish, WishState } from 'types'

import { AnimatedCard } from './Animator/AnimatedCard'
import { TagWishFullfilled } from './TagWishFulfilled'
import { TagWishIntended } from './TagWishIntended'

type Props = {
  wish?: Wish
  isLoading?: boolean
  basePath?: string
}

export const CardWish = ({
  wish,
  isLoading,
  basePath = '/',
}: Props): JSX.Element => {
  const { t } = useTranslation()

  const { user } = useLoggedUser()

  const isOwned = wish?.state === WishState.HAVE_IT
  const hasGiver = wish?.state === WishState.SOMEONE_IS_GOING_TO_GIFT
  const isOwnWish = wish?.userId === user?.id
  const isIntended = wish?.giver?.id === user?.id

  return (
    <AnimatedCard
      id={wish?.id}
      p={0}
      position="relative"
      pr={2}
      to={`${basePath}${wish?.id}`}
    >
      {isOwned && (
        <Box position="absolute" right={2} top={2} zIndex={10}>
          <TagWishFullfilled />
        </Box>
      )}
      {hasGiver && !isOwnWish && (
        <Box position="absolute" right={2} top={2} zIndex={10}>
          <TagWishIntended own={isIntended} />
        </Box>
      )}
      <HStack opacity={isOwned ? 0.3 : 1} spacing={4}>
        <Skeleton
          flexShrink={0}
          h={90}
          isLoaded={!isLoading}
          rounded="lg"
          w={[90, '30%']}
        >
          <Image
            alt="Avatar Alt"
            flexShrink={0}
            h={90}
            objectFit="cover"
            objectPosition={coordinatesToStyle(
              wish?.photoPosition as Coordinates,
            )}
            onError={setDefaultImage}
            rounded="lg"
            src={wish?.photoURL || '/empty_wish.png'}
            w="100%"
          />
        </Skeleton>
        <VStack overflow="hidden">
          <Skeleton isLoaded={!isLoading} maxW="100%">
            <Heading
              fontSize="xl"
              overflow="hidden"
              textAlign="left"
              textOverflow="ellipsis"
              w="100%"
              whiteSpace="nowrap"
            >
              {wish?.name || 'wish descriptive name'}
            </Heading>
          </Skeleton>
          <Text
            color="gray.500"
            fontWeight={600}
            mb={4}
            textAlign="left"
            w="100%"
          >
            <Skeleton
              as="span"
              display="inline"
              fontWeight="hairline"
              isLoaded={!isLoading}
            >
              {wish ? t('currency', { amount: wish?.price }) : 'wish price'}
            </Skeleton>
          </Text>
        </VStack>
      </HStack>
    </AnimatedCard>
  )
}
