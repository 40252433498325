import { deleteDoc, getDoc, setDoc } from 'firebase/firestore'
import {
  deleteObject,
  getDownloadURL,
  ref,
  uploadBytes,
  UploadResult,
} from 'firebase/storage'

import { uuid } from 'lib/uuid'

import { getFileRef, storage } from '.'

const regex = /images%(.+)\?alt/
const getTokenFromPhotoUrl = (url: string): string => {
  return regex.exec(url)?.[1] as string
}

export const uploadPhoto = async (file: File): Promise<UploadResult> => {
  const id = uuid()

  const path = `images/${id}`

  const reference = ref(storage, path)

  const result = await uploadBytes(reference, file)

  const url = await getDownloadURL(result.ref)

  const fileRef = getFileRef(getTokenFromPhotoUrl(url))

  await setDoc(fileRef, { path })

  return result
}

export const deletePhoto = async (photoUrl: string): Promise<void> => {
  const fileRef = getFileRef(getTokenFromPhotoUrl(photoUrl))

  const document = await getDoc(fileRef)
  const data = document.data()

  const { path } = data as { path: string }

  const storageRef = ref(storage, path)

  await deleteObject(storageRef)

  return deleteDoc(fileRef)
}
