import { Timestamp } from '@firebase/firestore'

import { SpecialDate } from 'types'

export const getNextDate = (day: number, month: number): Date => {
  const date = new Date()

  date.setMonth(month - 1)
  date.setDate(day)

  return toNextDate(date)
}

export const getNextTimestamp = (day: number, month: number): Timestamp => {
  return Timestamp.fromDate(getNextDate(day, month))
}

export const toNextDate = (date: Date): Date => {
  const fixedDate = new Date(date)

  if (fixedDate.getTime() < Date.now())
    fixedDate.setFullYear(fixedDate.getFullYear() + 1)

  return fixedDate
}

export const extractDate = (date: Date | Timestamp): Date =>
  date instanceof Date ? date : date.toDate()
export const extractTime = (date: Date | Timestamp): number =>
  extractDate(date).getTime()
export const extractNextDate = (date: Date | Timestamp): Date =>
  toNextDate(extractDate(date))
export const extractNextTime = (date: Date | Timestamp): number =>
  extractNextDate(date).getTime()

export const sortSpecialDates = <T>(dates: Array<T>): Array<T> => {
  return dates.sort((a, b) =>
    extractNextTime((a as unknown as SpecialDate).date) >
    extractNextTime((b as unknown as SpecialDate).date)
      ? 1
      : -1,
  )
}

export const isSameDay = (a: Timestamp, b: Timestamp): boolean => {
  const aDay = a.toDate().getDate()
  const bDay = b.toDate().getDate()
  const aMonth = b.toDate().getMonth()
  const bMonth = b.toDate().getMonth()

  return aDay === bDay && aMonth === bMonth
}
