import React from 'react'
import { createRoot } from 'react-dom/client'

import { App } from './App'
import './global-styles.scss'
import './i18n'
import reportWebVitals from './reportWebVitals'

if ('serviceWorker' in navigator) {
  navigator.serviceWorker.register('./firebase-messaging-sw.js')
    .then(function (registration) {
      // eslint-disable-next-line
      console.log('Registration was successful: ', registration)
    })
    .catch(function (e) {
      // eslint-disable-next-line
      console.error('Registration has filed', e)
    })
}

const container = document.getElementById('root')
const root = createRoot(container as HTMLElement)

root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
)

reportWebVitals()
