import { useTranslation } from 'react-i18next'
import { RiArrowGoBackFill } from 'react-icons/ri'
import { useLocation } from 'react-router-dom'

import { NavLink } from 'components/NavLink'
import { User } from 'types'

type Props = {
  own?: boolean
  user?: User
}

export const BreadcrumbWish = ({ own = true, user }: Props): JSX.Element => {
  const { t } = useTranslation()
  const { pathname } = useLocation()

  const to = own ? '/wishes' : pathname.split('/').slice(0, 3).join('/')

  return (
    <NavLink icon={<RiArrowGoBackFill />} maxW="auto" to={to}>
      {t(own ? 'breadcrumbs.wish' : 'breadcrumbs.friend_wish', {
        name: user?.displayName,
      })}
    </NavLink>
  )
}
