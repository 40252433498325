import { DocumentReference, Timestamp } from '@firebase/firestore'

export enum Langs {
  ES = 'es',
  EN = 'en',
}

export enum InteractionType {
  GIVE_INTENT = 'give_intent',
}

export type Interaction = {
  id: string
  type: InteractionType
}

export type YesOrNo = 'y' | 'n'

export type User = {
  isNew: boolean
  id: string
  displayName: string
  photoURL: string
  email: string
  location?: string
  nickName: string
  sentRequests: Array<string>
  receivedRequests: Array<string>
  createdAt: Timestamp
  updatedAt?: Timestamp
  interactions?: Array<Interaction>
  wishes?: Array<DocumentReference>
  wishesIds?: Array<string>
  dates?: Array<SpecialDate>
  friendsIds?: Array<string>
  friendsSince?: Record<string, Timestamp>
  details?: Array<WishDetail>
  ssoPhotoURL?: string
}

export enum WishState {
  WANT_IT = 'want_it',
  SOMEONE_IS_GOING_TO_GIFT = 'someone_is_going_to_gift',
  HAVE_IT = 'have_it',
}

export type WishDetail = {
  id: string
  type: WishDetailsType
  content: string
}

export type Wish = {
  name: string
  description: string
  price: number
  url: string
  id: string
  defaultPhotoURL: string
  photoURL: string
  photoPosition: Coordinates
  details: Array<WishDetail>
  createdAt?: Timestamp
  updatedAt?: Timestamp
  ownedAt?: Timestamp
  state?: WishState
  giver?: DocumentReference
  views?: Array<string>
  userId: string
}

export type WishCreateInput = Omit<Wish, 'id' | 'state' | 'giver' | 'userId'>

export enum WishDetailsType {
  SIZE = 'SIZE',
  SHOE_SIZE = 'SHOE_SIZE',
  PANT_SIZE = 'PANT_SIZE',
  UPPER_SIZE = 'UPPER_SIZE',
  CAP_SIZE = 'CAP_SIZE',
  GLASSESS_SIZE = 'GLASSESS_SIZE',
  DESTINATION = 'DESTINATION',
  ADDRESS = 'ADDRESS',
  COLOR = 'COLOR',
  FLAVOUR = 'FLAVOUR',
  MEMORY = 'MEMORY',
  POWER = 'POWER',
  MATERIAL = 'MATERIAL',
  EDITION = 'EDITION',
  VARIANT = 'VARIANT',
  RING_SIZE = 'RING_SIZE',
  OTHER = 'OTHER',
}

export type SpecialDate = {
  id: string
  name: string
  date: Timestamp
  repeat: boolean
}

export type SpecialDateWithUsers = SpecialDate & { users: Array<User> }

export enum UpdateType {
  ADD_WISH = 'ADD_WISH',
  OWN_WISH = 'OWN_WISH',
}

export type Update = {
  id: string
  userId: string
  date: Timestamp
  type: UpdateType
  meta: Record<string, string>
}

export enum NotificationType {
  FRIEND_REQUEST = 'FRIEND_REQUEST',
  FRIEND_REQUEST_ACCEPTED = 'FRIEND_REQUEST_ACCEPTED',
  WISH_ALREADY_FULFILLED = 'WISH_ALREADY_FULFILLED',
  WISH_RECEIVED = 'WISH_RECEIVED',
}

export enum NotificationState {
  NEW = 'NEW',
  SEEN = 'SEEN',
}

export type Notification = {
  state: NotificationState
  id: string
  userId: string
  date: Timestamp
  type: NotificationType
  meta: Record<string, string>
}

export type Coordinates = {
  x: number
  y: number
}
