import { Button } from '@chakra-ui/button'
import { useColorModeValue } from '@chakra-ui/color-mode'
import { useBoolean } from '@chakra-ui/hooks'
import { ExternalLinkIcon } from '@chakra-ui/icons'
import { Image } from '@chakra-ui/image'
import {
  Box,
  Flex,
  Heading,
  Link,
  SimpleGrid,
  Text,
  VStack
} from '@chakra-ui/layout'
import { Center, Spinner } from '@chakra-ui/react'
import { Tag } from '@chakra-ui/tag'
import { Tooltip } from '@chakra-ui/tooltip'
import { useEffect, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { RiGift2Line } from 'react-icons/ri'
import { useParams } from 'react-router'
import { Redirect } from 'react-router-dom'

import { BreadcrumbWish } from 'components/Breadcrumb/BreadcrumbWish'
import { DialogWishDelete } from 'components/DialogWishDelete'
import { DialogWishOwn } from 'components/DialogWishOwn'
import { ModalEditWish } from 'components/ModalEditWish'
import { TagsWish } from 'components/TagsWish'
import { useLoggedUser } from 'hooks/useLoggedUser'
import { useUser } from 'hooks/useUser'
import { useWish } from 'hooks/useWish'
import { coordinatesToStyle, getImageSize, ImageModes } from 'lib/style'
import {
  addView,
  intentToGive,
  removeIntentToGive
} from 'services/firebase/wish'
import { InteractionType, Wish, WishState } from 'types'

export const WishScreen = (): JSX.Element => {
  const { t } = useTranslation()
  const imageRef = useRef<HTMLImageElement>(null)
  const [givingLoading, setGivingLoading] = useBoolean()
  const [imageMode, setImageMode] = useState<ImageModes>(ImageModes.NO_IMAGE)
  const linkColor = useColorModeValue('primary.600', 'primary.300')
  const { user } = useLoggedUser()
  const { id, ownerId } = useParams<{ id: string; ownerId?: string }>()
  const { user: owner } = useUser(ownerId)
  const { wish, loading } = useWish(id)
  const isOwnWish = !ownerId
  const isFriendWish = !isOwnWish
  const isLoggedUserGiver =
    wish?.state === WishState.SOMEONE_IS_GOING_TO_GIFT &&
    user?.interactions?.some(
      (interaction) =>
        interaction.type === InteractionType.GIVE_INTENT &&
        interaction.id === id,
    )

  useEffect(() => {
    if (!isOwnWish && wish && user && !wish.views?.includes(user.id)) {
      addView(id, user.id)
    }
  }, [wish, id, isOwnWish, user])

  const imageWrapperProps = useMemo(() => {
    if (imageMode === ImageModes.HORIZONTAL) {
      return {
        gridColumn: '1 / span 2',
        gridRow: [3, 2],
        height: 'auto',
        maxHeight: 200,
        overflow: 'visible',
      }
    } else if (imageMode === ImageModes.VERTICAL) {
      return {
        gridColumn: [1, 2],
        height: imageRef.current?.naturalHeight,
        overflow: 'visible',
      }
    }

    return {}
  }, [imageMode])

  const handleLoadedImage = () => {
    if (imageRef.current) {
      const { naturalWidth, naturalHeight } = getImageSize(imageRef)
      const aspectRatio = naturalWidth / naturalHeight

      setImageMode(
        aspectRatio > 1 ? ImageModes.HORIZONTAL : ImageModes.VERTICAL,
      )
    }
  }

  const handleIntentToGive = async () => {
    if (!ownerId || !user || !wish) return

    setGivingLoading.on()
    if (isLoggedUserGiver) {
      await removeIntentToGive(user.id, ownerId, id)
    } else {
      await intentToGive(user.id, ownerId, id)
    }
    setGivingLoading.off()
  }

  if (!wish && !loading) {
    return <Redirect to="/wishes" />
  }

  // TODO: Add skeletons
  if (loading || !wish) {
    return (
      <Center h={'calc(100vh - 64px)'}>
        <Spinner size="xl" />
      </Center>
    )
  }

  const {
    url,
    photoURL,
    photoPosition,
    name,
    description,
    details,
    giver,
    state,
  } = wish as Wish

  const isOwned = state === WishState.HAVE_IT

  return (
    <VStack
      alignItems="start"
      height="calc(100vh - 64px)"
      marginX="auto"
      maxW={1200}
      overflowY="auto"
      px={[4, 4, 4, 4, 0]}
      spacing={6}
      w="100%"
    >
      <SimpleGrid
        columns={2}
        gap={4}
        gridTemplateColumns={['auto', '2fr 1fr']}
        py={4}
        w="100%"
      >
        <Box gridColumn="1/span 2">
          <BreadcrumbWish own={isOwnWish} user={owner} />
        </Box>

        <VStack alignItems="start" gridColumn={1} spacing={4}>
          <Heading size="3xl">{name}</Heading>
          <Link color={linkColor} href={url} isExternal>
            <ExternalLinkIcon mx="2px" /> {t('wish.link')}
          </Link>

          <TagsWish
            userIsGiver={giver?.id === user?.id}
            userIsOwner={isOwnWish}
            wish={wish}
          />

          <Text sx={{ lineBreak: 'anywhere' }}>{description}</Text>
        </VStack>

        {photoURL && (
          <Box
            borderRadius="lg"
            gridColumn={2}
            maxH="50vh"
            overflow="hidden"
            {...imageWrapperProps}
          >
            <Image
              alignSelf="center"
              alt={name}
              borderRadius="lg"
              height="100%"
              objectFit="cover"
              objectPosition={coordinatesToStyle(photoPosition)}
              onLoad={handleLoadedImage}
              ref={imageRef}
              src={photoURL}
              width="100%"
            />
          </Box>
        )}

        <VStack alignItems="start" gridColumn={[1, 2]}>
          {details.length && (
            <>
              <Heading size="xs">{t('preferences')}:</Heading>
              <Flex flexWrap="wrap" gridGap={2} pb={2}>
                {details?.map((detail) => {
                  return (
                    <Tag colorScheme="primary" key={detail.id}>
                      {t(`detail_name.${detail.type}`)}: {detail.content}
                    </Tag>
                  )
                })}
              </Flex>
            </>
          )}

          {!isOwned ? (
            <>
              <Heading size="xs">{t('actions')}:</Heading>
              {isOwnWish && <DialogWishOwn id={id} />}
              {isFriendWish && (
                <Button
                  colorScheme="primary"
                  isLoading={givingLoading}
                  justifyContent="space-between"
                  leftIcon={<RiGift2Line />}
                  onClick={handleIntentToGive}
                  w="100%"
                >
                  <Text flex={1}>
                    {isLoggedUserGiver ? (
                      <Tooltip bg="secondary.400" hasArrow label={t('cancel')}>
                        {t('wish.confirm_intention')}
                      </Tooltip>
                    ) : (
                      t('wish.has_intention')
                    )}
                  </Text>
                </Button>
              )}
              {isOwnWish && <ModalEditWish wish={wish as Wish} />}
              {isOwnWish && <DialogWishDelete id={id} />}
            </>
          ) : (
            <>
              <Heading pb={3} size="s">
                {isOwnWish ? t('own') : t('owns', { name: owner?.displayName })}
              </Heading>
              {isOwnWish && <DialogWishDelete id={id} />}
            </>
          )}
        </VStack>
      </SimpleGrid>
    </VStack>
  )
}
