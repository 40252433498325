import { useEffect } from 'react'

import { dismissNotificationsOfType } from 'services/firebase/notification'
import { NotificationType } from 'types'

import { useLoggedUser } from './useLoggedUser'

export const useDismissNotificationOfType = (
  type: NotificationType | Array<NotificationType>,
): void => {
  const { user } = useLoggedUser()

  useEffect(() => {
    dismissNotificationsOfType(user?.id as string, type)
  }, [user, type])
}
