import { Wrap } from '@chakra-ui/layout'
import { Spinner } from '@chakra-ui/spinner'
import { Tag, TagLabel, TagLeftIcon } from '@chakra-ui/tag'
import { Tooltip } from '@chakra-ui/tooltip'
import { useTranslation } from 'react-i18next'
import { AiFillEye, AiFillGift } from 'react-icons/ai'

import { Wish, WishState } from 'types'

import { TagWishFullfilled } from './TagWishFulfilled'

type Props = {
  wish?: Wish
  userIsOwner: boolean
  userIsGiver: boolean
}

export const TagsWish = ({
  wish,
  userIsOwner,
  userIsGiver,
}: Props): JSX.Element => {
  const { t } = useTranslation()

  if (!wish) {
    return <Spinner />
  }

  const { price, createdAt, updatedAt, ownedAt, views, giver, state } = wish

  const createDate = createdAt
  const updateDate = updatedAt && createdAt !== updatedAt && updatedAt

  const isOwned = state === WishState.HAVE_IT

  return (
    <Wrap>
      {isOwned && <TagWishFullfilled />}
      {!!views && !isOwned && (
        <Tooltip hasArrow label="Wish views">
          <Tag colorScheme="gray">
            <TagLeftIcon as={AiFillEye} />
            <TagLabel>{views.length}</TagLabel>
          </Tag>
        </Tooltip>
      )}
      {ownedAt && (
        <Tag colorScheme="primary">{t('wish.owned', { date: ownedAt })}</Tag>
      )}
      {!userIsOwner && !isOwned && giver !== undefined && (
        <Tag colorScheme="secondary">
          <TagLeftIcon as={AiFillGift} />
          <TagLabel>
            {userIsGiver
              ? t('wish.has_intention')
              : t('wish.someone_has_intention')}
          </TagLabel>
        </Tag>
      )}
      {!isOwned && (
        <>
          <Tag colorScheme="green">
            {t('wish.ref_price', { amount: price })}
          </Tag>
          <Tag colorScheme="gray">{t('wish.create', { date: createDate })}</Tag>
          {updateDate && (
            <Tag colorScheme="gray">
              {t('wish.update', { date: updateDate })}
            </Tag>
          )}
        </>
      )}
    </Wrap>
  )
}
