import { Text } from '@chakra-ui/layout'

import { Translate } from 'components/Translate'
import { Notification } from 'types'

import { NotificationWrapper } from './NotificationWrapper'

type Props = {
  notification: Notification
  onClick: () => void
}

export const NotificationWishAlreadyFulfilled = ({
  notification,
  onClick,
}: Props): JSX.Element => {
  const { meta } = notification

  return (
    <NotificationWrapper notification={notification} onClick={onClick}>
      <Text fontSize="xs">
        <Translate
          i18nKey="notifications.wish_already_fulfilled"
          values={{
            name: meta.userName,
            wish: meta.wishName,
          }}
        />
      </Text>
    </NotificationWrapper>
  )
}
