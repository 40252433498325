import { Button } from '@chakra-ui/button'
import { useDisclosure } from '@chakra-ui/hooks'
import { DeleteIcon } from '@chakra-ui/icons'
import { Text } from '@chakra-ui/layout'
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
} from '@chakra-ui/modal'
import { useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { useWish } from 'hooks/useWish'

type Props = {
  id: string
}

export const DialogWishDelete = ({ id }: Props): JSX.Element => {
  const { t } = useTranslation()
  const { isOpen, onOpen, onClose } = useDisclosure()
  const [loading, setLoading] = useState(false)
  const cancelRef = useRef<HTMLButtonElement>(null)
  const { deleteWish } = useWish(id)

  const handleDelete = async () => {
    setLoading(true)

    await deleteWish()
  }

  return (
    <>
      <Button
        colorScheme="secondary"
        justifyContent="space-between"
        leftIcon={<DeleteIcon />}
        onClick={onOpen}
        size="xs"
        w="100%"
      >
        <Text flex={1}>{t('dialog_wish_delete.open')}</Text>
      </Button>

      <AlertDialog
        isOpen={isOpen}
        leastDestructiveRef={cancelRef}
        onClose={onClose}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader>
              {t('dialog_wish_delete.title')}
            </AlertDialogHeader>

            <AlertDialogBody>{t('dialog_wish_delete.body')}</AlertDialogBody>

            <AlertDialogFooter>
              <Button disabled={loading} onClick={onClose} ref={cancelRef}>
                {t('cancel')}
              </Button>
              <Button
                colorScheme="red"
                isLoading={loading}
                ml={3}
                onClick={handleDelete}
                ref={cancelRef}
              >
                {t('delete')}
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  )
}
