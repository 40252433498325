import { Button } from '@chakra-ui/button'
import { Popover, PopoverContent, PopoverTrigger } from '@chakra-ui/popover'
import { Timestamp } from '@firebase/firestore'
import { DayPicker as RawDayPicker } from 'react-day-picker'
import 'react-day-picker/dist/style.css'
import { useTranslation } from 'react-i18next'

const styles = {
  '.DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside)': {
    bg: 'secondary.300',
    color: 'gray.900',
  },
  '.DayPicker-Day--today': {
    color: 'primary.300',
  },
  '.DayPicker:not(.DayPicker--interactionDisabled) .DayPicker-Day:not(.DayPicker-Day--disabled):not(.DayPicker-Day--selected):not(.DayPicker-Day--outside):hover':
  {
    bg: 'primary.500',
    color: 'primary.100',
  },
}

type Props = {
  value: Timestamp
  onChange: (date: Timestamp) => void
}

export const DayPicker = ({ value, onChange }: Props): JSX.Element => {
  const { t } = useTranslation()

  const handleChange = (date: Date) => {
    onChange(Timestamp.fromDate(date))
  }

  return (
    <Popover>
      <PopoverTrigger>
        <Button size="sm" w={68} >
          {t('date.short', { date: value })}
        </Button>
      </PopoverTrigger>
      <PopoverContent sx={styles}>
        <RawDayPicker onDayClick={handleChange} selected={value.toDate()} />
      </PopoverContent>
    </Popover>
  )
}
