import { Text } from '@chakra-ui/layout'

import { Translate } from 'components/Translate'
import { Update } from 'types'

import { FriendUpdateWrapper } from './Wrapper'

export const FriendUpdateWishAdded = ({
  update,
}: {
  update: Update
}): JSX.Element => {
  const { meta, userId } = update

  const links = [
    { href: `/friends/${userId}`, label: meta.userDisplayName },
    {
      href: `/friends/${userId}/wishes/${meta.wishId}`,
      label: meta.wishName,
    },
  ]

  return (
    <FriendUpdateWrapper links={links} update={update}>
      <Text fontSize={['xs', 'md']} lineHeight="1.5em">
        <Translate
          i18nKey="profile.friend_updates.wish_added"
          values={{
            name: meta.userDisplayName,
            wish: meta.wishName,
          }}
        />
      </Text>
    </FriendUpdateWrapper>
  )
}
