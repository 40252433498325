import { useColorModeValue } from '@chakra-ui/color-mode'
import { LinkIcon } from '@chakra-ui/icons'
import { Image } from '@chakra-ui/image'
import { Center, Flex, Text } from '@chakra-ui/layout'
import { HStack } from '@chakra-ui/react'
import { Skeleton, SkeletonText } from '@chakra-ui/skeleton'
import { useTranslation } from 'react-i18next'

import { Update } from 'types'

import { NavLink } from '../NavLink'

type Props = {
  update?: Update
  children?: React.ReactNode
  links?: Array<{
    label: string
    href: string
  }>
}

const defaultLinks = [{ label: 'link 1 label' }, { label: 'link 2 label' }]

export const FriendUpdateWrapper = ({
  update,
  children,
  links = [],
}: Props): JSX.Element => {
  const { t } = useTranslation()
  const isLoaded = Boolean(update)

  const { date, meta } = update || {
    meta: { photoURL: '/empty_wish.png', wishName: 'wish name' },
  }

  return (
    <HStack
      bg={useColorModeValue('gray.100', 'gray.900')}
      borderRadius="lg"
      boxShadow={['base', 'base', 'lg', 'xl']}
      p={2}
      spacing={4}
      w="100%"
    >
      <Skeleton isLoaded={isLoaded}>
        <Image
          alt={meta.wishName}
          borderRadius="md"
          fallbackSrc={'/empty_wish.png'}
          flexShrink={0}
          h={24}
          objectFit="cover"
          src={meta.photoURL || '/empty_wish.png'}
          w={[12, 24]}
        />
      </Skeleton>
      <Flex alignItems="start" direction="column" flex={1} h={24} minW={0}>
        <Skeleton isLoaded={isLoaded}>
          <Text
            color={useColorModeValue('primary.700', 'primary.300')}
            fontSize="xs"
          >
            {date ? t('date.timestamp', { date }) : '01/11/11, 11:11pm'}
          </Text>
        </Skeleton>
        <Center flex={1}>
          <SkeletonText isLoaded={isLoaded} noOfLines={2}>
            {isLoaded
              ? children
              : 'default skeleton content textfor a friend update'}
          </SkeletonText>
        </Center>
        <HStack
          color={useColorModeValue('secondary.800', 'secondary.300')}
          fontSize={12}
          justifyContent="end"
          w="100%"
        >
          {isLoaded
            ? links.map((link) => {
              return (
                <NavLink icon={<LinkIcon />} key={link.label} to={link.href}>
                  {link.label}
                </NavLink>
              )
            })
            : defaultLinks.map(({ label }, key) => (
              <Skeleton isLoaded={false} key={key}>
                {label}
              </Skeleton>
            ))}
        </HStack>
      </Flex>
    </HStack>
  )
}
