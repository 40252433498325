import { Notification } from 'types'

import { useLoggedUser } from './useLoggedUser'
import { useResource } from './useResource'

type UseNotifications = {
  notifications: Array<Notification>
}

export const useNotifications = (): UseNotifications => {
  const { user } = useLoggedUser()

  const { data: notifications } = useResource<Array<Notification>>(
    `notification?userId=${user?.id}&order=date:desc&limit=10`,
  )

  return { notifications: notifications || [] }
}
