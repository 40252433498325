import { Avatar } from '@chakra-ui/avatar'
import { Text, VStack } from '@chakra-ui/layout'
import { HStack } from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'

import { DeferredLink } from 'components/DeferredLink'
import { Notification, NotificationState } from 'types'

type Props = {
  notification: Notification
  onClick: () => void
  children: React.ReactNode
  isAvatar?: boolean
}

export const NotificationWrapper = ({
  notification,
  onClick,
  children,
  isAvatar,
}: Props): JSX.Element => {
  const { t } = useTranslation()
  const { date, meta, state } = notification

  const isNew = state === NotificationState.NEW

  return (
    <HStack
      as={DeferredLink}
      borderLeftColor={isNew ? 'secondary.600' : 'none'}
      borderLeftWidth={isNew ? 3 : 0}
      borderRadius="lg"
      boxShadow="md"
      onClick={onClick}
      p={2}
      spacing={2}
      style={{
        opacity: isNew ? 1 : 0.7,
      }}
      to={meta.href}
    >
      <Avatar
        borderRadius={isAvatar ? 'full' : 'md'}
        name={isAvatar ? meta.displayName : meta.wishName}
        src={meta.photoURL}
      />
      <VStack alignItems="start">
        <Text color="primary.300" fontSize="xs">
          {t('date.timestamp', { date })}
        </Text>
        {children}
      </VStack>
    </HStack>
  )
}
