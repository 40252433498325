import { useColorModeValue } from '@chakra-ui/color-mode'
import { Text, VStack } from '@chakra-ui/layout'
import { Skeleton } from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'

import { SpecialDate } from 'types'

type Props = {
  specialDate: SpecialDate
  isLoading: boolean
}

export const IncomingDate = ({
  specialDate: { name, date },
  isLoading,
}: Props): JSX.Element => {
  const { t } = useTranslation()

  return (
    <VStack
      alignItems="start"
      bg={useColorModeValue('secondary.100', 'secondary.200')}
      borderRadius="md"
      boxShadow={['base', 'base', 'lg', 'xl']}
      color={useColorModeValue('secondary.800', 'secondary.900')}
      p={2}
      px={4}
      spacing={2}
      w="100%"
    >
      <Skeleton isLoaded={!isLoading}>
        <Text fontSize="md" fontWeight="semibold">
          {name}
        </Text>
      </Skeleton>
      <Skeleton isLoaded={!isLoading}>
        <Text>{t('date.short', { date })}</Text>
      </Skeleton>
      ?
    </VStack>
  )
}
