import { useMemo } from 'react'

import { isSameDay, sortSpecialDates } from 'lib/date'
import { SpecialDateWithUsers } from 'types'

import { useUserFriends } from './useUserFriends'

type UseSpecialDates = {
  specialDates: Array<SpecialDateWithUsers>
  isLoadingSpecialDates: boolean
}

export const useSpecialDates = (userId: string): UseSpecialDates => {
  const { friends, loading } = useUserFriends(userId)

  const specialDates = useMemo(() => {
    if (!friends) return []
    const dates: Array<SpecialDateWithUsers> = []

    friends.forEach((friend) => {
      friend.dates?.forEach((specialDate) => {
        const target = dates.find(
          (d) =>
            isSameDay(d.date, specialDate.date) && specialDate.name === d.name,
        )

        if (target) {
          target.users.push(friend)
        } else {
          dates.push({
            ...specialDate,
            users: [friend],
          })
        }
      })
    })

    return sortSpecialDates(dates)
  }, [friends])

  return {
    isLoadingSpecialDates: loading,
    specialDates,
  }
}
