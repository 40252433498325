import { IconButton } from '@chakra-ui/button'
import { useDisclosure } from '@chakra-ui/hooks'
import { BellIcon } from '@chakra-ui/icons'
import {
  Drawer,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
} from '@chakra-ui/modal'
import { DrawerBody } from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'

import { useLoggedUser } from 'hooks/useLoggedUser'
import { useNotifications } from 'hooks/useNotifications'
import { dismissNotificationsOfType } from 'services/firebase/notification'
import { Notification, NotificationState, NotificationType } from 'types'

import { NotificationFriendRequest } from './NotificationFriendRequest'
import { NotificationFriendRequestAccepted } from './NotificationFriendRequestAccepted'
import { NotificationWishAlreadyFulfilled } from './NotificationWishAlreadyFulfilled'
import { NotificationWishReceived } from './NotificationWishReceived'

const renderNotification = (
  notification: Notification,
  handleClose: () => void,
) => {
  const bundle = {
    key: notification.id,
    notification,
    onClick: handleClose,
  }

  switch (notification.type) {
    case NotificationType.FRIEND_REQUEST:
      return <NotificationFriendRequest {...bundle} />
    case NotificationType.FRIEND_REQUEST_ACCEPTED:
      return <NotificationFriendRequestAccepted {...bundle} />
    case NotificationType.WISH_ALREADY_FULFILLED:
      return <NotificationWishAlreadyFulfilled {...bundle} />
    case NotificationType.WISH_RECEIVED:
      return <NotificationWishReceived {...bundle} />
    default:
      throw new Error(`Notification type not supported: ${notification.type}`)
  }
}

export const Notifications = (): JSX.Element => {
  const { t } = useTranslation()
  const { user } = useLoggedUser()
  const { notifications } = useNotifications()

  const hasNotifications = notifications.some(
    ({ state }) => state === NotificationState.NEW,
  )

  const { isOpen, onOpen, onClose } = useDisclosure()

  const handleClose = () => {
    onClose()

    dismissNotificationsOfType(user?.id as string, [
      NotificationType.WISH_ALREADY_FULFILLED,
      NotificationType.WISH_RECEIVED,
    ])
  }

  return (
    <>
      <IconButton
        aria-label="notifications"
        colorScheme={hasNotifications ? 'red' : 'gray'}
        icon={<BellIcon />}
        onClick={onOpen}
      />
      <Drawer isOpen={isOpen} onClose={handleClose}>
        <DrawerOverlay />
        <DrawerContent>
          <DrawerHeader>{t('notifications.title')}</DrawerHeader>
          <DrawerCloseButton />
          <DrawerBody>
            {notifications.map((notification) =>
              renderNotification(notification, handleClose),
            )}
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </>
  )
}
