import { Wish } from 'types'

import { useResource } from './useResource'

type UseUser = {
  loading: boolean
  wishes?: Array<Wish>
}

export const useUserWishes = (userId: string): UseUser => {
  const { data: wishes, loading } = useResource<Array<Wish>>(
    `wish?userId=${userId}`,
  )

  return { loading, wishes }
}
