import { Button } from '@chakra-ui/button'
import { Input } from '@chakra-ui/input'
import { Flex, Text, VStack } from '@chakra-ui/layout'
import { ChangeEvent } from 'react'
import { useTranslation } from 'react-i18next'

type Props = {
  handleNext: () => void
  handleBack: () => void
  value: string
  onChange: (event: ChangeEvent<HTMLInputElement>) => void
}

export const DisplayName = ({
  handleNext,
  handleBack,
  value,
  onChange,
}: Props): JSX.Element => {
  const { t } = useTranslation()

  return (
    <>
      <Text color="primary.300">{t('new_user.display_name.body')}</Text>
      <VStack>
        <Input name="displayName" onChange={onChange} value={value} />
      </VStack>
      <Flex justifyContent="space-between">
        <Button colorScheme="secondary" onClick={handleBack}>
          {t('back')}
        </Button>
        <Button
          colorScheme="primary"
          disabled={value.length === 0}
          onClick={handleNext}
        >
          {t('next')}
        </Button>
      </Flex>
    </>
  )
}
