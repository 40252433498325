import { User as AuthUser } from '@firebase/auth'
import {
  deleteField,
  getDoc,
  getDocs,
  onSnapshot,
  query,
  serverTimestamp,
  setDoc,
  updateDoc,
  where,
} from '@firebase/firestore'

import { User } from 'types'

import { getUserCollectionReference, getUserRef } from '.'

export const createUserIfMisssing = async (
  authUser: AuthUser,
): Promise<void> => {
  const { displayName, uid: id, email, photoURL } = authUser

  const userRef = getUserRef(id)

  const snapshot = await getDoc(userRef)

  if (!snapshot.exists()) {
    await setDoc(userRef, {
      createdAt: serverTimestamp(),
      displayName,
      email,
      id,
      isNew: true,
      photoPosition: { x: 50, y: 50 },
      photoURL,
      ssoPhotoURL: photoURL,
    })
  }
}

export const subscribeToUserSnapshot = (
  id: string,
  callback: (user: User) => void,
): (() => void) => {
  return onSnapshot(getUserRef(id), (snapshot) => {
    callback(snapshot.data() as User)
  })
}

export const checkNicknameAvailability = async (
  nickName: string,
  userId: string,
): Promise<boolean> => {
  const collection = getUserCollectionReference()

  const q = query(collection, where('nickName', '==', nickName))

  const docs = await getDocs(q)

  const isAvailable = docs.size === 0

  const userRef = getUserRef(userId)
  if (isAvailable) {
    await updateDoc(userRef, {
      nickName,
    })
  } else {
    await updateDoc(userRef, {
      nickName: deleteField(),
    })
  }

  return isAvailable
}

export const updateUser = async (
  userId: string,
  data: Partial<User>,
): Promise<void> => {
  const userRef = getUserRef(userId)

  await updateDoc(userRef, data)
}
