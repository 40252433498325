import { Heading, Text } from '@chakra-ui/layout'
import { useColorModeValue } from '@chakra-ui/system'

export const Logo = (): JSX.Element => {
  return (
    <Heading fontFamily="heading" fontWeight="100" size="lg">
      <Text as="span">wan</Text>
      <Text
        as="strong"
        color={useColorModeValue('primary.600', 'secondary.300')}
      >
        that
      </Text>
    </Heading>
  )
}
