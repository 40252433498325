import { Update } from 'types'

import { useLoggedUser } from './useLoggedUser'
import { useResource } from './useResource'

type UseFriendsUpdates = {
  loading: boolean
  updates?: Array<Update>
}

export const useFriendsUpdates = (): UseFriendsUpdates => {
  const { user } = useLoggedUser()

  const { data: updates, loading } = useResource<Array<Update>>(
    `update?userId=in:${user?.friendsIds?.toString()}&order=date:desc&limit=10`,
  )

  return { loading, updates }
}
