import { Text, useColorModeValue } from '@chakra-ui/react'
import { Trans } from 'react-i18next'

type Props = {
  i18nKey: string
  values?: Record<string, unknown>
}

export const Translate = ({ i18nKey, values }: Props): JSX.Element => {
  const colorSecondary = useColorModeValue('secondary.500', 'secondary.300')

  return (
    <Trans
      components={{
        b: <strong />,
        bcp: <Text as="strong" color="primary.300" />,
        bcs: <Text as="strong" color={colorSecondary} />,
        cp: <Text as="span" color="primary.300" />,
        cs: <Text as="span" color={colorSecondary} />,
        jmp: <br />,
      }}
      i18nKey={i18nKey}
      values={values}
    >
      <Text color="red">WARNING: Translation key not found: {i18nKey}</Text>
    </Trans>
  )
}
