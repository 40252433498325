import { Button, IconButton } from '@chakra-ui/button'
import { useColorModeValue } from '@chakra-ui/color-mode'
import { FormControl, FormLabel } from '@chakra-ui/form-control'
import { AddIcon, DeleteIcon } from '@chakra-ui/icons'
import { Input } from '@chakra-ui/input'
import { Flex, Text, VStack } from '@chakra-ui/layout'
import { Menu, MenuButton, MenuItem, MenuList } from '@chakra-ui/menu'
import { HStack } from '@chakra-ui/react'
import { ChangeEvent } from 'react'
import { useTranslation } from 'react-i18next'
import { FaPen } from 'react-icons/fa'

import { WishDetail, WishDetailsType } from 'types'

import { AnimatedList } from './Animator/AnimatedList'

type Props = {
  details: Array<WishDetail>
  setFieldValue: (field: string, value: unknown) => void
}

export const FormControlDetails = ({
  details,
  setFieldValue,
}: Props): JSX.Element => {
  const { t } = useTranslation()

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target

    setFieldValue(name, value)
  }

  const handleAddDetail = (type: WishDetailsType) => {
    setFieldValue('details', [...details, { content: '', id: type, type }])
  }

  const handleRemoveDetail = (index: number) => {
    setFieldValue(
      'details',
      details.filter((d, i) => i !== index),
    )
  }

  const options = Object.entries(WishDetailsType).filter(
    (keyValue) => !details.find(({ id }) => id === keyValue[0]),
  )

  return (
    <FormControl id="details">
      <Flex justifyContent="space-between" w="100%">
        <HStack
          as={FormLabel}
          color={useColorModeValue('primary.600', 'primary.100')}
        >
          <FaPen />
          <Text>{t('details')}</Text>
        </HStack>
        <Menu>
          <MenuButton
            as={Button}
            colorScheme="primary"
            rightIcon={<AddIcon />}
            size="xs"
          >
            {t('add')}
          </MenuButton>
          <MenuList>
            {options.map(([key, value]) => {
              return (
                <MenuItem
                  key={key}
                  onClick={() => handleAddDetail(key as WishDetailsType)}
                  value={key}
                >
                  {t(`detail_name.${value}`)}
                </MenuItem>
              )
            })}
          </MenuList>
        </Menu>
      </Flex>

      <VStack py={2} spacing={1}>
        <AnimatedList items={details}>
          {(detail, index) => {
            const { id, type, content } = detail as WishDetail

            return (
              <Flex
                alignItems="center"
                gridGap={[2]}
                justifyContent="space-between"
                key={id}
                p={2}
                shadow="md"
                w="100%"
              >
                <Text w={110}>{t(`detail_name.${type}`)}</Text>
                <Input
                  flex={1}
                  name={`details[${index}].content`}
                  onChange={handleChange}
                  placeholder={t('modal_edit_profile.detail_placeholder')}
                  value={content}
                />

                <IconButton
                  aria-label="delete"
                  icon={<DeleteIcon />}
                  onClick={() => handleRemoveDetail(index)}
                  size="sm"
                />
              </Flex>
            )
          }}
        </AnimatedList>
      </VStack>
    </FormControl>
  )
}
