import { Box, VStack } from '@chakra-ui/react'
import { AnimatePresence } from 'framer-motion'
import { useEffect } from 'react'
import { Redirect, Route, Switch, useLocation } from 'react-router-dom'

import { AnimatedRoute } from 'components/Animator/AnimatedRoute'
import { FullScreenLoader } from 'components/FullScreenLoader'
import { NavBar } from 'components/NavBar'
import { useLoggedUser } from 'hooks/useLoggedUser'
import { requestPushNotificationPermission } from 'services/firebase'

import { Friend } from './screens/Friend'
import { Friends } from './screens/Friends'
import { Profile } from './screens/Profile'
import { WishScreen } from './screens/Wish'
import { Wishes } from './screens/Wishes'

export const Dashboard = (): JSX.Element => {
  const { loading, user } = useLoggedUser()

  useEffect(() => {
    if (!loading && user) requestPushNotificationPermission()
  }, [loading, user])

  const location = useLocation()

  if (loading) {
    return <FullScreenLoader visible />
  }

  if (!user) {
    return <Redirect to="/login" />
  }

  if (user.isNew) {
    return <Redirect to="/onboard" />
  }

  return (
    <Box>
      <VStack w="100%">
        <NavBar />
        <AnimatePresence initial={false} mode="wait">
          <Switch key={location.pathname} location={location}>
            <Route exact path="/">
              <Redirect to="/profile" />
            </Route>
            <AnimatedRoute exact path="/profile">
              <Profile />
            </AnimatedRoute>
            <AnimatedRoute exact path="/wishes">
              <Wishes />
            </AnimatedRoute>
            <AnimatedRoute exact path="/wishes/:id">
              <WishScreen />
            </AnimatedRoute>
            <AnimatedRoute exact path="/friends">
              <Friends />
            </AnimatedRoute>
            <AnimatedRoute exact path="/friends/:id">
              <Friend />
            </AnimatedRoute>
            <AnimatedRoute exact path="/friends/:ownerId/wishes/:id">
              <WishScreen />
            </AnimatedRoute>
          </Switch>
        </AnimatePresence>
      </VStack>
    </Box>
  )
}
