import { useColorModeValue } from '@chakra-ui/color-mode'
import { Text } from '@chakra-ui/layout'
import { AlertDialogBody } from '@chakra-ui/modal'
import { useTranslation } from 'react-i18next'

export const SomeoneBougthIt = (): JSX.Element => {
  const { t } = useTranslation()

  return (
    <AlertDialogBody
      color={useColorModeValue('secondary.600', 'secondary.400')}
    >
      <Text pb={2}>{t('dialog_wish_own.bought.alert')}</Text>
      <Text color={useColorModeValue('gray.600', 'gray.400')} fontSize="xs">
        {t('dialog_wish_own.bought.side')}
      </Text>
    </AlertDialogBody>
  )
}
