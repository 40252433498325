import { deleteWish as rawDeleteWish } from 'services/firebase/wish'
import { Wish } from 'types'

import { useResource } from './useResource'

type UseWish = {
  loading: boolean
  wish?: Wish
  deleteWish: () => void
}

export const useWish = (id: string): UseWish => {
  const { data: wish, loading, deleteItem } = useResource<Wish>(`wish/${id}`)

  const deleteWish = async () => {
    deleteItem()
    await rawDeleteWish(id)
  }

  return { deleteWish, loading, wish }
}
