import { Button } from '@chakra-ui/button'
import { HStack, Text } from '@chakra-ui/layout'
import { Skeleton } from '@chakra-ui/skeleton'
import { useEffect, useState } from 'react'

import { useLoggedUser } from 'hooks/useLoggedUser'
import {
  acceptFriendRequest,
  removeFriendRequest,
} from 'services/firebase/friend'
import { subscribeToUserSnapshot } from 'services/firebase/user'
import { User } from 'types'

type Props = { id: string }

export const FriendRequest = ({ id }: Props): JSX.Element => {
  const { user } = useLoggedUser()
  const safeUser = user as User

  const [requester, setRequester] = useState<User>()

  useEffect(() => {
    const unsubscribe = subscribeToUserSnapshot(id, (user) => {
      setRequester(user as User)
    })

    return () => unsubscribe()
  }, [setRequester, id])

  const acceptRequest = async () => {
    await acceptFriendRequest(safeUser, id)
  }
  const rejectRequest = async () => {
    await removeFriendRequest(id, safeUser.id)
  }

  return (
    <HStack
      alignItems="center"
      bg="secondary.300"
      borderRadius="lg"
      p={3}
      spacing={2}
      w="100%"
    >
      <Text as="p" color="gray.700" flex={1}>
        <Skeleton as="span" isLoaded={Boolean(requester)} mr="1">
          {requester?.displayName || 'Johnathan Doe'}
        </Skeleton>
        wants to be your friend
      </Text>
      <Button onClick={acceptRequest} size="xs" variant="gray">
        accept
      </Button>
      <Button onClick={rejectRequest} size="xs" variant="gray">
        decline
      </Button>
    </HStack>
  )
}
