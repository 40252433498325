import React from 'react'

import { User } from 'types'

export type UserContextValue = {
  user?: User
  handleLogout: () => Promise<void>
  loading: boolean
}

export const UserContext = React.createContext<UserContextValue>({
  handleLogout: () => Promise.resolve(),
  loading: true,
  user: undefined,
})
