import { Box, BoxProps, useColorModeValue } from '@chakra-ui/react'
import { motion, Variants } from 'framer-motion'

import { DeferredLink } from '../DeferredLink'

const variants: Variants = {
  animate: {
    opacity: 1
  },
  hidden: { opacity: 0 },
}

type Props = {
  children: React.ReactNode
  id?: string
  to?: string
} & BoxProps

export const AnimatedCard = ({
  children,
  id,
  to,
  p,
  pr,
  pl,
  pb,
  pt,
  position,
}: Props): JSX.Element => {
  return (
    <Box
      animate="animate"
      as={motion.article}
      display="flex"
      exit="hidden"
      flexBasis={['100%', '48%']}
      flexGrow={[1, 0]}
      initial="hidden"
      layoutId={id}
      position={position}
      rounded="lg"
      variants={variants}
      w="full"
    >
      <Box
        _hover={{
          backgroundColor: useColorModeValue('gray.200', 'primary.800'),
        }}
        as={DeferredLink}
        bg={useColorModeValue('gray.100', 'gray.900')}
        boxShadow={['base', 'base', 'lg', 'xl']}
        p={p}
        pb={pb}
        pl={pl}
        pr={pr}
        pt={pt}
        rounded="lg"
        textAlign="center"
        to={to}
        w="100%"
      >
        {children}
      </Box>
    </Box>
  )
}
