import { onSnapshot } from 'firebase/firestore'
import { useCallback, useRef, useState } from 'react'

import { DataContextValue } from 'context/DataContext'
import { parseResource } from 'lib/cache'
import { buildQueryRef, getGenericRef } from 'services/firebase'

type Resource = {
  data: unknown
  loading: boolean
}

type Store = Record<string, Resource>

type UseDataContext = DataContextValue

export const useDataContext = (): UseDataContext => {
  const { current: subscribers } = useRef<Record<string, string[]>>({})
  const { current: unsubscribers } = useRef<Record<string, () => void>>({})
  const { current: deleters } = useRef<Record<string, () => void>>({})
  const [data, setData] = useState<Store>({})

  const subscribe = useCallback(
    (resource: string, uid: string): void => {
      if (subscribers[resource]) {
        subscribers[resource].push(uid)
      } else {
        subscribers[resource] = [uid]
      }

      if (data[resource] && unsubscribers[resource]) {
        return
      }

      setData((oldData) => ({
        ...oldData,
        [resource]: {
          data: oldData[resource]?.data || null,
          loading: oldData[resource]?.loading ?? true,
        },
      }))

      const { collection, id, queryParams } = parseResource(resource)

      let unsubscribe: () => void

      if (queryParams) {
        const q = buildQueryRef(collection, queryParams)

        unsubscribe = onSnapshot(q, (snapshot) => {
          const list: Array<unknown> = []
          const sideChain: Record<string, Resource> = {}

          snapshot.forEach((s) => {
            const id = s.id
            const data = s.data()
            const item = { ...data, id }
            list.push(item)

            const individualResourcePath = `${collection}/${id}`

            sideChain[individualResourcePath] = {
              data,
              loading: false,
            }
          })

          setData((oldData) => ({
            ...oldData,
            ...sideChain,
            [resource]: {
              data: list,
              loading: false,
            },
          }))
        })
      } else {
        unsubscribe = onSnapshot(
          getGenericRef(collection, id as string),
          (snapshot) => {
            if (snapshot.exists()) {
              const datum = {
                id,
                ...snapshot.data(),
              }

              setData((oldData) => ({
                ...oldData,
                [resource]: {
                  data: datum,
                  loading: false,
                },
              }))
            } else {
              setData((oldData) => ({
                ...oldData,
                [resource]: {
                  data: oldData[resource]?.data,
                  loading: false,
                },
              }))
            }
          },
        )
      }

      unsubscribers[resource] = unsubscribe

      deleters[resource] = () => {
        unsubscribe()

        setData((oldData) => ({
          ...oldData,
          [resource]: {
            data: null,
            loading: false,
          },
        }))
      }
    },
    [subscribers, data, unsubscribers, deleters],
  )

  const unsubscribe = useCallback(
    (resource: string, uid: string): void => {
      subscribers[resource] = subscribers[resource]?.filter((id) => id !== uid)

      if (subscribers[resource].length === 0) {
        unsubscribers[resource]()
        delete unsubscribers[resource]
      }
    },
    [subscribers, unsubscribers],
  )

  const deleteResource = useCallback(
    (resource: string): void => {
      deleters[resource]?.()
    },
    [deleters],
  )

  const read = useCallback(
    <T>(resource: string): { loading: boolean; item?: T } => {
      const datum = data[resource]

      return {
        item: datum?.data as T,
        loading: datum?.loading ?? true,
      }
    },
    [data],
  )

  return {
    data,
    delete: deleteResource,
    read,
    subscribe,
    unsubscribe,
  }
}
