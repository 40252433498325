import { Translate } from 'components/Translate'
import { Notification } from 'types'

import { NotificationWrapper } from './NotificationWrapper'

type Props = {
  notification: Notification
  onClick: () => void
}

export const NotificationFriendRequestAccepted = ({
  notification,
  onClick,
}: Props): JSX.Element => {
  const { meta } = notification

  return (
    <NotificationWrapper isAvatar notification={notification} onClick={onClick}>
      <Translate
        i18nKey="notifications.friend_request_accepted"
        values={{ name: meta.displayName }}
      />
    </NotificationWrapper>
  )
}
