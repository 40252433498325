import { IconButton } from '@chakra-ui/button'
import { useDisclosure } from '@chakra-ui/hooks'
import { HamburgerIcon } from '@chakra-ui/icons'
import {
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerOverlay,
} from '@chakra-ui/modal'
import { VStack } from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'
import { FaGifts, FaUserAlt, FaUsers } from 'react-icons/fa'

import { Logo } from 'components/Logo'
import { NavLink } from 'components/NavLink'

export const HamburguerMenu = (): JSX.Element => {
  const { t } = useTranslation()
  const { isOpen, onOpen, onClose } = useDisclosure()

  const waitAndClose = () => {
    setTimeout(onClose, 200)
  }

  return (
    <>
      <Drawer isOpen={isOpen} onClose={onClose} placement="left">
        <DrawerOverlay />
        <DrawerContent>
          <DrawerBody>
            <VStack alignItems="start" spacing={5}>
              <Logo />
              <NavLink
                icon={<FaUserAlt />}
                sideEffect={waitAndClose}
                size={24}
                to="/profile"
              >
                {t('navbar.home_mobile')}
              </NavLink>
              <NavLink
                icon={<FaUsers />}
                sideEffect={waitAndClose}
                size={24}
                to="/friends"
              >
                {t('navbar.friends')}
              </NavLink>
              <NavLink
                icon={<FaGifts />}
                sideEffect={waitAndClose}
                size={24}
                to="/wishes"
              >
                {t('navbar.wishes')}
              </NavLink>
            </VStack>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
      <IconButton aria-label="menu" icon={<HamburgerIcon />} onClick={onOpen} />
      <Logo />
    </>
  )
}
