import { Button } from '@chakra-ui/button'
import { useDisclosure } from '@chakra-ui/hooks'
import { PlusSquareIcon } from '@chakra-ui/icons'
import { Text } from '@chakra-ui/layout'
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
} from '@chakra-ui/modal'
import { HStack, RadioGroup } from '@chakra-ui/react'
import { useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { FaCoins, FaGift, FaShoppingBag } from 'react-icons/fa'

import { CustomRadio } from 'components/CustomRadio'
import { useLoggedUser } from 'hooks/useLoggedUser'
import { useUser } from 'hooks/useUser'
import { useWish } from 'hooks/useWish'
import { generateNotification } from 'lib/notification'
import { setAsOwned } from 'services/firebase/wish'
import { NotificationType, Wish, YesOrNo } from 'types'

import { GiftReceived } from './GiftReceived'
import { SomeoneBougthIt } from './SomeoneBoughtIt'
import { WaitForTheGift } from './WaitForTheGift'

type Props = {
  id: string
}

enum States {
  INTENT = 'intent',
  BOUGHT = 'bought',
  GIFT = 'gift',
}

export const DialogWishOwn = ({ id }: Props): JSX.Element => {
  const { t } = useTranslation()
  const { user } = useLoggedUser()
  const { isOpen, onOpen, onClose } = useDisclosure()
  const { wish } = useWish(id)
  const [loading, setLoading] = useState(false)
  const [state, setState] = useState<string | undefined>()
  const { user: giver } = useUser(wish?.giver?.id)
  const [identityConfirmed, setIdentityConfirmed] = useState<
    YesOrNo | undefined
  >()

  const safeWish = wish as Wish
  const hasGiver = Boolean(safeWish?.giver)
  const wantsToBuyButHasGiver = hasGiver && state === States.INTENT
  const boughtButHasGiver = hasGiver && state === States.BOUGHT
  const wishReceived = hasGiver && state === States.GIFT

  const filed =
    (Boolean(state) && !hasGiver) ||
    wantsToBuyButHasGiver ||
    boughtButHasGiver ||
    (wishReceived && identityConfirmed)

  const notificationType = useMemo(() => {
    if (!hasGiver) return undefined

    if (wantsToBuyButHasGiver || boughtButHasGiver)
      return NotificationType.WISH_ALREADY_FULFILLED

    if (wishReceived) {
      if (identityConfirmed === 'y') return NotificationType.WISH_RECEIVED
      if (identityConfirmed === 'n')
        return NotificationType.WISH_ALREADY_FULFILLED
    }

    return undefined
  }, [
    identityConfirmed,
    hasGiver,
    wantsToBuyButHasGiver,
    boughtButHasGiver,
    wishReceived,
  ])
  const cancelRef = useRef<HTMLButtonElement>(null)

  const handleConfirm = async () => {
    try {
      setLoading(true)

      await setAsOwned(
        wish as Wish,
        notificationType &&
          giver &&
          generateNotification(giver.id, notificationType, {
            href: `/friends/${user?.id}/wishes/${wish?.id}`,
            photoURL: wish?.photoURL as string,
            userName: user?.displayName as string,
            wishName: wish?.name as string,
          }),
      )
    } catch (e) {
      setLoading(false)
      onClose()
    }
  }

  return (
    <>
      <Button
        colorScheme="primary"
        justifyContent="space-between"
        leftIcon={<PlusSquareIcon />}
        onClick={onOpen}
        size="xs"
        w="100%"
      >
        <Text flex={1}>{t('dialog_wish_own.open')}</Text>
      </Button>

      <AlertDialog
        isOpen={isOpen}
        leastDestructiveRef={cancelRef}
        onClose={onClose}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader>{t('dialog_wish_own.title')}</AlertDialogHeader>

            <AlertDialogBody>{t('dialog_wish_own.body')}</AlertDialogBody>

            <RadioGroup onChange={(v) => setState(v)}>
              <HStack justifyContent="space-evenly" px={6} py={2}>
                <CustomRadio value={States.INTENT}>
                  <FaCoins size={20} />
                  <Text maxW="8ch" textAlign="center">
                    {t('dialog_wish_own.going_to.button')}
                  </Text>
                </CustomRadio>
                <CustomRadio value={States.BOUGHT}>
                  <FaShoppingBag size={20} />
                  <Text maxW="8ch" textAlign="center">
                    {t('dialog_wish_own.bought.button')}
                  </Text>
                </CustomRadio>
                <CustomRadio value={States.GIFT}>
                  <FaGift size={20} />
                  <Text maxW="8ch" textAlign="center">
                    {t('dialog_wish_own.received.button')}
                  </Text>
                </CustomRadio>
              </HStack>
            </RadioGroup>

            {wantsToBuyButHasGiver && <WaitForTheGift />}
            {boughtButHasGiver && <SomeoneBougthIt />}
            {wishReceived && giver && (
              <GiftReceived
                identityConfirmed={identityConfirmed}
                setIdentityConfirmed={setIdentityConfirmed}
                user={giver}
              />
            )}

            <AlertDialogFooter>
              <Button disabled={loading} onClick={onClose} ref={cancelRef}>
                {t('cancel')}
              </Button>
              <Button
                colorScheme="primary"
                disabled={!filed || loading}
                isLoading={loading}
                ml={3}
                onClick={handleConfirm}
                ref={cancelRef}
              >
                {t('confirm')}
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  )
}
