import { Box, Heading, VStack } from '@chakra-ui/layout'
import { useBreakpointValue } from '@chakra-ui/media-query'
import { SimpleGrid, Spinner } from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'
import { Redirect, useLocation, useParams } from 'react-router'

import { BreadcrumbFriend } from 'components/Breadcrumb/BreadcrumbFriend'
import { DialogFriendDelete } from 'components/DialogFriendDelete'
import { IncomingDate } from 'components/IncomingDate'
import { UserProfile } from 'components/UserProfile'
import { WishList } from 'components/WishList'
import { useDismissNotificationOfType } from 'hooks/useDismissNotification'
import { useLoggedUser } from 'hooks/useLoggedUser'
import { useUser } from 'hooks/useUser'
import { sortSpecialDates } from 'lib/date'
import { NotificationType, SpecialDate, User } from 'types'

const getNextThreeDates = (
  amount: number,
  dates?: Array<SpecialDate>,
): Array<SpecialDate> => {
  if (!dates) return []

  return sortSpecialDates(dates).slice(0, amount)
}

const mockDate = {
  date: new Date(),
  name: 'a date',
}
const mockValues = {
  dates: [mockDate, mockDate, mockDate].map((m, id) => ({ ...m, id })),
}

export const Friend = (): JSX.Element => {
  const { t } = useTranslation()
  const { pathname } = useLocation()
  const { user: loggedUser } = useLoggedUser()
  const { id } = useParams<{ id: string }>()

  useDismissNotificationOfType([NotificationType.FRIEND_REQUEST_ACCEPTED])

  const { user, loading } = useUser(id)

  const maxVisibleDates = useBreakpointValue([3, 5, 6])

  if (!loggedUser?.friendsIds?.includes(id)) {
    return <Redirect to="/friends" />
  }

  const { dates } = (user as User) || mockValues

  const friendshipSince = loggedUser?.friendsSince?.[id]

  return (
    <VStack
      alignItems="center"
      height="calc(100vh - 64px)"
      marginX="auto"
      maxW={1200}
      overflowY="auto"
      px={[4, 4, 4, 4, 0]}
      py={[2, 6]}
      spacing={6}
      w="100%"
    >
      <Box w="100%">
        <BreadcrumbFriend />
      </Box>
      <UserProfile friendsSince={friendshipSince} user={user} />
      <SimpleGrid
        columns={[1, 2]}
        gap={[2, 4]}
        templateColumns={[null, 'auto 1fr']}
        w="100%"
      >
        <VStack spacing={[2, 4]} w={['100%', '24ch']}>
          <Heading size="sm">{t('friend.incoming_dates')}</Heading>
          <VStack w="100%">
            {getNextThreeDates(maxVisibleDates || 2, dates).map(
              (specialDate) => (
                <IncomingDate
                  isLoading={loading}
                  key={specialDate.id}
                  specialDate={specialDate}
                />
              ),
            )}
          </VStack>
        </VStack>
        <VStack alignItems={['center', 'center', 'start']} spacing={[2, 4]}>
          <Heading size="sm">{t('friend.wishes')}</Heading>
          {loading ? (
            <Spinner />
          ) : (
            <WishList basePath={`${pathname}/wishes`} user={user as User} />
          )}
        </VStack>
        <VStack gridColumn={1}>
          <Heading size="sm">{t('options')}</Heading>
          <DialogFriendDelete friendId={id} />
        </VStack>
      </SimpleGrid>
    </VStack>
  )
}
