import { Button, IconButton } from '@chakra-ui/button'
import { useColorModeValue } from '@chakra-ui/color-mode'
import { useDisclosure } from '@chakra-ui/hooks'
import { AddIcon, DeleteIcon, EditIcon } from '@chakra-ui/icons'
import { Flex, Text, VStack } from '@chakra-ui/layout'
import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/modal'
import { FormControl, FormHelperText, FormLabel, Input } from '@chakra-ui/react'
import { Select } from '@chakra-ui/select'
import { Textarea } from '@chakra-ui/textarea'
import { useFormik } from 'formik'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { FaSave } from 'react-icons/fa'
import { useParams } from 'react-router'

import { uuid } from 'lib/uuid'
import { useToast } from 'hooks/useToast'
import { useLoggedUser } from 'hooks/useLoggedUser'
import { updateWish } from 'services/firebase/wish'
import { Wish, WishDetail, WishDetailsType } from 'types'

import { AnimatedList } from './Animator/AnimatedList'
import { ImageEditor } from './ImageEditor'

type Props = {
  wish: Wish
}

export const ModalEditWish = ({ wish }: Props): JSX.Element => {
  const { t } = useTranslation()
  const { user } = useLoggedUser()
  const toast = useToast()
  const { id } = useParams<{ id: string }>()
  const { isOpen, onOpen, onClose } = useDisclosure()
  const [loading, setLoading] = useState(false)

  const handleOpen = () => {
    setValues(wish)
    onOpen()
  }

  const handleClose = () => {
    onClose()
  }

  const { handleSubmit, handleChange, values, setFieldValue, setValues } =
    useFormik({
      initialValues: wish,
      onSubmit: async (values) => {
        if (!user || wish.userId !== user.id) {
          toast.error(t('modal_edit_wish.toast_forbidden'))

          return
        }

        try {
          setLoading(true)
          await updateWish(id, values)
          toast.success(t('modal_edit_wish.toast_submit_success'))
          setLoading(false)
          handleClose()
        } catch (e) {
          setLoading(false)
          toast.error('Failed to update wish! Please, try again')
        }
      },
    })

  const handleAddDetail = () => {
    setFieldValue('details', [
      ...values.details,
      { content: '', id: uuid(), type: 'size' },
    ])
  }

  const handleRemoveDetail = (detailId: string) => {
    setFieldValue(
      'details',
      values.details.filter((detail) => detail.id !== detailId),
    )
  }

  return (
    <>
      <Button
        colorScheme="primary"
        justifyContent="space-between"
        leftIcon={<EditIcon />}
        onClick={handleOpen}
        size="xs"
        w="100%"
      >
        <Text flex={1}>{t('modal_edit_wish.open')}</Text>
      </Button>

      <Modal isOpen={isOpen} onClose={handleClose}>
        <ModalOverlay />
        <ModalContent
          mb={[0, '3.75em']}
          minHeight={['100vh', 'auto']}
          mt={[0, '3.75em']}
        >
          <ModalHeader
            color={useColorModeValue('primary.400', 'primary.200')}
            fontFamily="heading"
            fontWeight={100}
          >
            {t('modal_edit_wish.heading', { name: wish.name })}
          </ModalHeader>
          <ModalCloseButton />
          <form onSubmit={handleSubmit}>
            <ModalBody>
              <VStack mt={4} spacing={6}>
                <ImageEditor
                  defaultPhotoURL={wish.photoURL}
                  photoPosition={values.photoPosition}
                  photoURL={values.photoURL}
                  setFieldValue={setFieldValue}
                  uploadDisabled={values.url.length === 0}
                />
                <FormControl id="url">
                  <FormLabel>{t('modal_edit_wish.link_label')}</FormLabel>
                  <Input
                    onChange={handleChange}
                    type="text"
                    value={values.url}
                  />
                  <FormHelperText>
                    {t('modal_edit_wish.link_helper')}
                  </FormHelperText>
                </FormControl>

                <FormControl id="name">
                  <FormLabel>{t('modal_edit_wish.name_label')}</FormLabel>
                  <Input
                    onChange={handleChange}
                    type="text"
                    value={values.name}
                  />
                  <FormHelperText>
                    {t('modal_edit_wish.name_helper')}
                  </FormHelperText>
                </FormControl>

                <FormControl id="price">
                  <FormLabel>{t('modal_edit_wish.price_label')}</FormLabel>
                  <Input
                    onChange={handleChange}
                    type="number"
                    value={values.price}
                  />
                  <FormHelperText>
                    {t('modal_edit_wish.price_helper')}
                  </FormHelperText>
                </FormControl>

                <FormControl id="details">
                  <Flex justifyContent="space-between" w="100%">
                    <FormLabel>{t('modal_edit_wish.details_label')}</FormLabel>
                    <Button
                      onClick={handleAddDetail}
                      rightIcon={<AddIcon />}
                      size="xs"
                    >
                      {t('add')}
                    </Button>
                  </Flex>

                  <VStack py={2} spacing={1}>
                    <AnimatedList items={values.details}>
                      {(detail, i) => {
                        const { id } = detail as WishDetail

                        return (
                          <Flex
                            gridGap={2}
                            justifyContent="space-between"
                            key={id}
                            w="100%"
                          >
                            <Select
                              flex={1}
                              name={`details[${i}].type`}
                              onChange={handleChange}
                              size="xs"
                              value={values.details[i].type}
                              variant="filled"
                            >
                              {Object.entries(WishDetailsType).map(
                                ([key, value]) => {
                                  return (
                                    <option key={key} value={value}>
                                      {t(`detail_name.${value}`)}
                                    </option>
                                  )
                                },
                              )}
                            </Select>
                            <Input
                              flex={2}
                              name={`details[${i}].content`}
                              onChange={handleChange}
                              size="xs"
                              value={values.details[i].content}
                            />
                            <IconButton
                              aria-label="remove detail"
                              icon={<DeleteIcon />}
                              onClick={() => handleRemoveDetail(id)}
                              size="xs"
                              variant="secondary"
                            />
                          </Flex>
                        )
                      }}
                    </AnimatedList>
                  </VStack>
                </FormControl>

                <FormControl id="description">
                  <FormLabel>
                    {t('modal_edit_wish.description_label')}
                  </FormLabel>
                  <Textarea
                    onChange={handleChange}
                    resize="none"
                    value={values.description}
                  />
                </FormControl>
              </VStack>
            </ModalBody>
            <ModalFooter>
              <Button
                isLoading={loading}
                rightIcon={<FaSave />}
                type="submit"
                variant="primary"
              >
                {t('save')}
              </Button>
            </ModalFooter>
          </form>
        </ModalContent>
      </Modal>
    </>
  )
}
