import {
  Accordion,
  AccordionButton,
  AccordionItem,
  AccordionPanel,
} from '@chakra-ui/accordion'
import { Avatar } from '@chakra-ui/avatar'
import { useColorModeValue } from '@chakra-ui/color-mode'
import { Heading, Text, VStack } from '@chakra-ui/layout'
import { AlertDialogBody } from '@chakra-ui/modal'
import { HStack, RadioGroup } from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'
import { FaQuestion, FaRegThumbsUp } from 'react-icons/fa'

import { CustomRadio } from 'components/CustomRadio'
import { User, YesOrNo } from 'types'

export const GiftReceived = ({
  user,
  identityConfirmed,
  setIdentityConfirmed,
}: {
  user: User
  identityConfirmed?: YesOrNo
  setIdentityConfirmed: (v: YesOrNo) => void
}): JSX.Element => {
  const { t } = useTranslation()
  const negativeColor = useColorModeValue('secondary.600', 'secondary.400')
  const displayName = user?.displayName

  return (
    <>
      <AlertDialogBody color={useColorModeValue('primary.600', 'primary.300')}>
        {t('dialog_wish_own.received.body')}
      </AlertDialogBody>
      <Accordion>
        <AccordionItem>
          <AccordionButton>
            {t('dialog_wish_own.received.gifter')}
          </AccordionButton>
          <AccordionPanel>
            <VStack w="100%">
              <HStack>
                <Avatar name={displayName} size="xs" src={user?.photoURL} />
                <Heading size="s">{displayName}</Heading>
              </HStack>
              <RadioGroup onChange={(v) => setIdentityConfirmed(v as YesOrNo)}>
                <HStack justifyContent="space-evenly" px={6} py={2}>
                  <CustomRadio value={'y'}>
                    <FaRegThumbsUp size={20} />
                    <Text maxW="8ch" textAlign="center">
                      {t('dialog_wish_own.received.confirm')}
                    </Text>
                  </CustomRadio>
                  <CustomRadio value={'n'}>
                    <FaQuestion size={20} />
                    <Text maxW="8ch" textAlign="center">
                      {t('dialog_wish_own.received.deny')}
                    </Text>
                  </CustomRadio>
                </HStack>
              </RadioGroup>

              {identityConfirmed === 'y' && (
                <Text>
                  {t('dialog_wish_own.received.confirm_side', {
                    name: displayName,
                  })}
                </Text>
              )}

              {identityConfirmed === 'n' && (
                <Text color={negativeColor}>
                  {t('dialog_wish_own.received.deny_side', {
                    name: displayName,
                  })}
                </Text>
              )}
            </VStack>
          </AccordionPanel>
        </AccordionItem>
      </Accordion>
    </>
  )
}
