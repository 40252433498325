import { Timestamp } from 'firebase/firestore'

import { User } from 'types'

import { useResource } from './useResource'

type UseUser = {
  loading: boolean
  friends?: Array<User>
}

export const useUserFriends = (
  userId: string
): UseUser => {
  const { data, loading } = useResource<Array<User>>(
    `user?friendsIds=array-contains:${userId}`,
  )

  const now = new Date()

  const friends = data?.map((friend) => {
    return {
      ...friend,
      dates: friend.dates?.map((specialDate) => {
        const date = specialDate.date.toDate()

        if (date < now) {
          date.setFullYear(now.getFullYear())

          if (date < now) {
            date.setFullYear(now.getFullYear() + 1)
          }

          return {
            ...specialDate,
            date: Timestamp.fromDate(date),
          }
        }

        return specialDate
      }),
    }
  })

  return { friends, loading }
}
