import { Button } from '@chakra-ui/button'
import { useDisclosure } from '@chakra-ui/hooks'
import { WarningIcon } from '@chakra-ui/icons'
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
} from '@chakra-ui/modal'
import { useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { useLoggedUser } from 'hooks/useLoggedUser'
import { deleteFriendship } from 'services/firebase/friend'

type Props = {
  friendId: string
}

export const DialogFriendDelete = ({ friendId }: Props): JSX.Element => {
  const { t } = useTranslation()
  const { user } = useLoggedUser()
  const { isOpen, onOpen, onClose } = useDisclosure()
  const [loading, setLoading] = useState(false)
  const cancelRef = useRef<HTMLButtonElement>(null)

  const handleDelete = async () => {
    setLoading(true)

    await deleteFriendship(user?.id as string, friendId)
  }

  return (
    <>
      <Button
        colorScheme="red"
        leftIcon={<WarningIcon />}
        onClick={onOpen}
        size="xs"
        w="100%"
      >
        {t('dialog_friend_delete.open')}
      </Button>

      <AlertDialog
        isOpen={isOpen}
        leastDestructiveRef={cancelRef}
        onClose={onClose}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader>
              {t('dialog_friend_delete.title')}
            </AlertDialogHeader>

            <AlertDialogBody>{t('dialog_friend_delete.body')}</AlertDialogBody>

            <AlertDialogFooter>
              <Button disabled={loading} onClick={onClose} ref={cancelRef}>
                {t('cancel')}
              </Button>
              <Button
                colorScheme="red"
                isLoading={loading}
                ml={3}
                onClick={handleDelete}
                ref={cancelRef}
              >
                {t('confirm')}
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  )
}
