export type CacheQuery = {
  type: string
  meta: unknown
}

type CacheResource = {
  collection: string
  id?: string
  queryParams?: Array<CacheQuery>
  name: string
}

const parseQueryParams = (str: string): Array<CacheQuery> => {
  return str.split('&').map((src) => {
    const [target, param] = src.split('=')

    let type
    let meta

    try {
      switch (target) {
        case 'limit':
          type = 'limit'

          meta = { value: Number(param) }

          break
        case 'order': {
          type = 'orderBy'

          const [field, direction] = param.split(':')

          meta = { direction, field }

          break
        }
        default: {
          type = 'where'

          const bundle = param.split(':')
          let op = bundle[0]
          let value

          switch (op) {
            case 'in':
              value = bundle[1].split(',')
              break
            default:
              value = bundle[1]
          }

          if (value === undefined) {
            value = op
            op = '=='
          }

          meta = { field: target, op, value }

          break
        }
      }

      return {
        meta,
        type,
      }
    } catch {
      throw new Error('Error parsing cache query. Chech ' + str)
    }
  })
}

export const parseResource = (input: string): CacheResource => {
  const [path, rawQuery] = input.split('?')

  const [collection, id] = path.split('/')

  return {
    collection,
    id,
    name: input,
    queryParams: rawQuery ? parseQueryParams(rawQuery) : undefined,
  }
}
