import { Link as ChackraLink, Text, useColorModeValue } from '@chakra-ui/react'
import { ReactNode } from 'react'
import { useLocation } from 'react-router-dom'

import { DeferredLink } from './DeferredLink'

type Props = {
  children: ReactNode
  icon?: ReactNode
  to: string
  size?: number
  sideEffect?: () => void
  maxW?: string
}

export const NavLink = ({
  children,
  to,
  icon,
  size,
  sideEffect,
  maxW,
}: Props): JSX.Element => {
  const { pathname } = useLocation()

  const isIn = pathname.startsWith(to)
  const selectedColor = useColorModeValue('primary.600', 'primary.200')

  return (
    <ChackraLink
      _hover={{
        bg: useColorModeValue('gray.200', 'gray.700'),
        textDecoration: 'none',
      }}
      as={DeferredLink}
      color={isIn ? selectedColor : 'inherit'}
      display="flex"
      flexShrink={1}
      fontSize={size}
      gridGap={2}
      href={'#'}
      maxW={maxW || ['15ch', '40ch']}
      minW={0}
      onClick={sideEffect}
      px={2}
      py={1}
      rounded={'md'}
      to={to}
    >
      {icon}
      <Text
        as="span"
        display="inline-block"
        flexShrink={1}
        overflow="hidden"
        textOverflow="ellipsis"
        whiteSpace="nowrap"
      >
        {children}
      </Text>
    </ChackraLink>
  )
}
