import { AnimatePresence, motion, Variants } from 'framer-motion'

type Props = {
  items: Array<{ id: string | number } & Record<string, unknown>>
  children: (item: unknown, index: number) => JSX.Element
}

const variants: Variants = {
  hidden: {
    maxHeight: 0,
    paddingBottom: 0,
    transition: {
      duration: 0.35,
    },
  },
  visible: {
    maxHeight: 100,
    paddingBottom: 8,
    transition: {
      duration: 0.35,
    },
  },
}

const child: Variants = {
  hidden: { opacity: 0, x: -50 },
  visible: {
    opacity: 1,
    transition: {
      delay: 0.1,
    },
    x: 0,
  },
}

export const AnimatedList = ({ items, children }: Props): JSX.Element => {
  return (
    <AnimatePresence initial={false}>
      {items.map((item, index) => {
        return (
          <motion.article
            animate="visible"
            exit="hidden"
            initial="hidden"
            key={item.id}
            style={{ width: '100%' }}
            variants={variants}
          >
            <motion.div variants={child}>{children(item, index)}</motion.div>
          </motion.article>
        )
      })}
    </AnimatePresence>
  )
}
