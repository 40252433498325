import { Avatar } from '@chakra-ui/avatar'
import { Heading, VStack, Wrap } from '@chakra-ui/layout'
import { Skeleton, SkeletonCircle } from '@chakra-ui/skeleton'
import { Tag, TagLabel, TagLeftIcon } from '@chakra-ui/tag'
import { Timestamp } from '@firebase/firestore'
import { useTranslation } from 'react-i18next'
import { TiLocation } from 'react-icons/ti'

import { User } from 'types'

type Props = {
  user: User | undefined
  friendsSince?: Timestamp
}

const userPlaceholders = {
  createdAt: null,
  dates: new Date(),
  displayName: 'John Doe',
  location: 'Madrid',
  nickName: '@johndow',
  photoURL: '',
}

export const UserProfile = ({ user, friendsSince }: Props): JSX.Element => {
  const { t } = useTranslation()
  const isLoaded = Boolean(user)
  const { displayName, nickName, photoURL, createdAt, location } =
    user || userPlaceholders

  return (
    <VStack spacing={4}>
      <SkeletonCircle isLoaded={isLoaded} size={'96px'}>
        <Avatar name={displayName} size="xl" src={photoURL} />
      </SkeletonCircle>
      <Skeleton isLoaded={isLoaded}>
        <Heading size="2xl">{displayName}</Heading>
      </Skeleton>
      <Skeleton isLoaded={isLoaded}>
        <Heading color="primary.300" fontWeight="xs" size="sm">
          {nickName}
        </Heading>
      </Skeleton>
      <Wrap>
        {location && (
          <Skeleton isLoaded={isLoaded}>
            <Tag colorScheme="secondary">
              <TagLeftIcon as={TiLocation} />
              <TagLabel> {location}</TagLabel>
            </Tag>
          </Skeleton>
        )}
        <Skeleton isLoaded={isLoaded}>
          {createdAt && (
            <Tag>
              {t(
                friendsSince
                  ? 'user_profile.joined'
                  : 'user_profile.joined_own',
                { date: createdAt },
              )}
            </Tag>
          )}
        </Skeleton>
        <Skeleton isLoaded={isLoaded}>
          {friendsSince && (
            <Tag colorScheme="green">
              {t('user_profile.friends', { date: friendsSince })}
            </Tag>
          )}
        </Skeleton>
      </Wrap>
    </VStack>
  )
}
