import { RefObject } from 'react'

import { Coordinates } from 'types'

export enum ImageModes {
  NO_IMAGE,
  HORIZONTAL,
  VERTICAL,
}

export const getImageSize = (
  ref: RefObject<HTMLImageElement>,
): {
  naturalHeight: number
  naturalWidth: number
} => {
  const { naturalHeight, naturalWidth } = ref.current || {
    naturalHeight: 300,
    naturalWidth: 200,
  }

  return { naturalHeight, naturalWidth }
}

export const coordinatesToStyle = (
  position: Coordinates | string | undefined,
): string => {
  if (!position || typeof position === 'string') return '50% 50%'

  const { x, y } = position
  return `${x}% ${y}%`
}
