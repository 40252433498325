import {
  addDoc,
  getDocs,
  query,
  Timestamp,
  updateDoc,
  where,
  writeBatch
} from '@firebase/firestore'

import { Notification, NotificationState, NotificationType } from 'types'

import { db, getNotificationCollectionReference, getNotificationRef } from '.'

const getMeta = (): Record<string, string> => {
  return {}
}

export const createNotification = async (
  type: NotificationType,
  userId: string,
): Promise<void> => {
  const notificationRef = getNotificationCollectionReference()

  const notification: Omit<Notification, 'id'> = {
    date: Timestamp.now(),
    meta: getMeta(),
    state: NotificationState.NEW,
    type,
    userId,
  }

  await addDoc(notificationRef, notification)
}

export const dismissNotification = async (id: string): Promise<void> => {
  const notificationRef = getNotificationRef(id)

  await updateDoc(notificationRef, {
    state: NotificationState.SEEN,
  })
}

export const dismissNotificationsOfType = async (
  userId: string,
  types: NotificationType | Array<NotificationType>,
): Promise<void> => {
  const notificationRef = getNotificationCollectionReference()

  const q = query(
    notificationRef,
    where('userId', '==', userId),
    where('state', '==', NotificationState.NEW),
    where('type', 'in', Array.isArray(types) ? types : [types]),
  )

  const batch = writeBatch(db)

  const docs = await getDocs(q)

  docs.forEach((doc) => {
    batch.update(doc.ref, { state: NotificationState.SEEN })
  })

  return batch.commit()
}
