import { LockIcon, MoonIcon, SunIcon } from '@chakra-ui/icons'
import {
  Avatar,
  Button,
  Center,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
  useColorMode,
  useColorModeValue,
} from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'
import { FaLanguage } from 'react-icons/fa'
import { useHistory } from 'react-router'

import { useLoggedUser } from 'hooks/useLoggedUser'
import { getAppVersion } from 'services/app_version'

const version = getAppVersion()

const lngs: Record<string, string> = {
  en: 'English',
  es: 'Español',
}

export const UserMenu = (): JSX.Element => {
  const { push } = useHistory()
  const { user, handleLogout } = useLoggedUser()
  const { colorMode, toggleColorMode } = useColorMode()
  const { t, i18n } = useTranslation()
  const toggleLanguage = () => {
    i18n.changeLanguage(i18n.resolvedLanguage === 'en' ? 'es' : 'en')
  }

  return (
    <Menu closeOnBlur>
      <MenuButton
        as={Button}
        cursor={'pointer'}
        minW={0}
        rounded={'full'}
        variant={'link'}
      >
        <Avatar size={'sm'} src={user?.photoURL} />
      </MenuButton>
      <MenuList alignItems={'center'}>
        <Center p={2}>
          <Avatar size={'2xl'} src={user?.photoURL} />
        </Center>
        <Center p={2}>
          <p>{user?.displayName}</p>
        </Center>
        <MenuDivider />
        <MenuItem
          icon={colorMode === 'light' ? <MoonIcon /> : <SunIcon />}
          onClick={toggleColorMode}
        >
          {t('navbar.usermenu.colormode')}
        </MenuItem>
        <MenuItem icon={<FaLanguage />} onClick={toggleLanguage}>
          {lngs[i18n.resolvedLanguage]}
        </MenuItem>
        <MenuItem
          icon={<LockIcon />}
          onClick={() => {
            push('/')
            handleLogout()
          }}
        >
          {t('navbar.usermenu.logout')}
        </MenuItem>
        <MenuDivider />
        <MenuItem
          color={useColorModeValue('primary.700', 'primary.300')}
          fontSize="xs"
          pointerEvents="none"
        >
          wanthat {version}
        </MenuItem>
      </MenuList>
    </Menu>
  )
}
