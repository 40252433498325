import { Avatar } from '@chakra-ui/avatar'
import { Button } from '@chakra-ui/button'
import { useColorModeValue } from '@chakra-ui/color-mode'
import { Heading, HStack, Text, VStack } from '@chakra-ui/layout'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { useLoggedUser } from 'hooks/useLoggedUser'
import {
  acceptFriendRequest,
  addFriendRequest,
  removeFriendRequest
} from 'services/firebase/friend'
import { User } from 'types'

type Props = {
  user: User
}

export const UserCard = ({ user }: Props): JSX.Element => {
  const { t } = useTranslation()
  const { user: loggedUser } = useLoggedUser()

  const [loading, setLoading] = useState(false)

  const handleFriendRequest = async () => {
    setLoading(true)

    await addFriendRequest(loggedUser as User, user.id)

    setLoading(false)
  }

  const handleCancelFriendRequest = async () => {
    setLoading(true)

    await removeFriendRequest(loggedUser?.id as string, user.id)

    setLoading(false)
  }

  const handleAcceptFriendRequest = async () => {
    setLoading(true)

    await acceptFriendRequest(loggedUser as User, user.id)

    setLoading(false)
  }

  const friendRequestReceived = loggedUser?.receivedRequests?.includes(user.id)
  const friendRequestSent = loggedUser?.sentRequests?.includes(user.id)

  return (
    <HStack p={2} spacing={4} w="100%">
      <Avatar
        name={user.displayName as string}
        size="lg"
        src={user.photoURL as string}
      />
      <VStack alignItems="start">
        <Heading as="h5" fontSize={20}>
          {user.displayName}
        </Heading>
        <Text color={useColorModeValue('primary.700', 'secondary.300')}>
          {user.nickName}
        </Text>

        {friendRequestSent ? (
          <HStack>
            <Button
              disabled={loading}
              onClick={handleCancelFriendRequest}
              size="xs"
              variant="secondary"
            >
              {t('modal_add_friend.request.cancel')}
            </Button>
          </HStack>
        ) : friendRequestReceived ? (
          <Button
            disabled={loading}
            onClick={handleAcceptFriendRequest}
            size="xs"
            variant="secondary"
          >
            {t('modal_add_friend.request.accept')}
          </Button>
        ) : (
          <Button
            disabled={loading}
            onClick={handleFriendRequest}
            size="xs"
            variant="secondary"
          >
            {t('modal_add_friend.request.add')}
          </Button>
        )}
      </VStack>
    </HStack>
  )
}
