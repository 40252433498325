import { HStack, SimpleGrid, Text, VStack } from '@chakra-ui/layout'
import { AnimatePresence, motion, Variants } from 'framer-motion'
import { useTranslation } from 'react-i18next'

import { CardFriend } from 'components/CardFriend'
import { FriendRequest } from 'components/FriendRequest'
import { ModalSearchFriend } from 'components/ModalSearchFriend'
import { useDismissNotificationOfType } from 'hooks/useDismissNotification'
import { useLoggedUser } from 'hooks/useLoggedUser'
import { useUserFriends } from 'hooks/useUserFriends'
import { NotificationType, User } from 'types'

const commonMotionProps = {
  animate: 'visible',
  exit: 'hidden',
  initial: 'hidden',
}

const requestVariants: Variants = {
  hidden: { height: 0, marginBottom: 0, marginTop: 0, opacity: 0 },
  visible: {
    height: 48,
    marginBottom: 4,
    marginTop: 4,
    opacity: 1,
  },
}

const requestMotionProps = {
  ...commonMotionProps,
  style: { width: '100%' },
  variants: requestVariants,
}

const getList = (
  user: User,
  friends: User[],
  loading: boolean,
): Array<User> => {
  if (!user.friendsIds) return []

  if (loading) return user?.friendsIds?.map((id) => ({ id } as User))

  return user.friendsIds.map((id) => {
    return friends.find((friend) => friend.id === id) as User
  })
}

export const Friends = (): JSX.Element => {
  const { user } = useLoggedUser()
  const { friends, loading } = useUserFriends(user?.id as string)
  const { t } = useTranslation()

  const { receivedRequests } = user as User

  useDismissNotificationOfType([
    NotificationType.FRIEND_REQUEST,
    NotificationType.FRIEND_REQUEST_ACCEPTED,
  ])

  const list = getList(user as User, friends as User[], loading)

  return (
    <VStack
      height="calc(100vh - 64px)"
      marginX="auto"
      maxW={1200}
      overflowY="auto"
      pb={4}
      px={[4, 4, 4, 4, 0]}
      w="100%"
    >
      <VStack w="100%">
        <AnimatePresence>
          {receivedRequests?.map((id) => (
            <motion.article key={id} layoutId={id} {...requestMotionProps}>
              <FriendRequest id={id} />
            </motion.article>
          ))}
        </AnimatePresence>
      </VStack>
      <HStack as="header" justifyContent="space-between" py={4} w="100%">
        <Text>{t('friends.heading')}</Text>
        <ModalSearchFriend />
      </HStack>
      <SimpleGrid columns={[1, 1, 2, 2, 3]} spacing={4} w="100%">
        <AnimatePresence>
          {list?.map((friend) => (
            <CardFriend isLoading={loading} key={friend?.id} user={friend} />
          ))}
        </AnimatePresence>
      </SimpleGrid>
    </VStack>
  )
}
