import { CheckIcon } from '@chakra-ui/icons'
import { Tag } from '@chakra-ui/tag'
import { Tooltip } from '@chakra-ui/tooltip'
import { useTranslation } from 'react-i18next'

export const TagWishIntended = ({ own }: { own?: boolean }): JSX.Element => {
  const { t } = useTranslation()

  return (
    <Tooltip
      hasArrow
      label={t(
        own
          ? 'tag_wish_intended.tooltip_own'
          : 'tag_wish_intended.tooltip_other',
      )}
    >
      <Tag colorScheme="secondary">
        <CheckIcon />
      </Tag>
    </Tooltip>
  )
}
