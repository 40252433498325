import { Link } from 'react-router-dom'

import { useBrowserHistory } from './BrowserHistoryProvider'

type Props = {
  to: string
  children: React.ReactNode
  onClick?: () => void
} & Record<string, unknown>

export const DeferredLink = ({
  children,
  to,
  ...props
}: Props): JSX.Element => {
  const { push } = useBrowserHistory()

  return (
    <Link
      {...props}
      onClick={(e) => {
        e.preventDefault()

        props.onClick?.()

        requestAnimationFrame(() => push(to))
      }}
      to={to}
    >
      {children}
    </Link>
  )
}
