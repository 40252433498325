import { Flex, Heading, Text } from '@chakra-ui/layout'
import { Progress } from '@chakra-ui/react'
import { useFormik } from 'formik'
import { AnimatePresence, motion } from 'framer-motion'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Redirect } from 'react-router-dom'

import { useLoggedUser } from 'hooks/useLoggedUser'
import { getNextTimestamp } from 'lib/date'
import { uuid } from 'lib/uuid'
import { updateUser } from 'services/firebase/user'

import { FormSteps } from './common'
import { DefaultDetails } from './DefaultDetails'
import { DisplayName } from './DisplayName'
import { Location } from './Location'
import { NickNameCheck } from './NickNameCheck'
import { Resume } from './Resume'
import { SpecialDates } from './SpecialDates'

const wrapper = {
  hidden: { opacity: 0 },
  visible: {
    opacity: 1,
    transition: {
      staggerChildren: 2,
      when: 'beforeChildren',
    },
  },
}

const fadeInFromLeft = {
  hidden: { opacity: 0, x: -100 },
  visible: { opacity: 1, transition: { duration: 1.5 }, x: 0 },
}

const fadeIn = {
  hidden: { opacity: 0 },
  visible: { opacity: 1, transition: { duration: 1 } },
}

export const NewUserScreen = (): JSX.Element => {
  const { t } = useTranslation()
  const [step, setStep] = useState(0)

  const { user } = useLoggedUser()

  const { values, setFieldValue, handleChange, handleSubmit, isSubmitting } =
    useFormik({
      initialValues: {
        dates: user?.dates || [
          {
            date: getNextTimestamp(24, 12),
            id: uuid(),
            name: t('new_user.dates.christmas'),
            repeat: true,
          },
          {
            date: getNextTimestamp(5, 1),
            id: uuid(),
            name: t('new_user.dates.wisemen'),
            repeat: true,
          },
        ],
        details: user?.details || [],
        displayName: user?.displayName || '',
        location: user?.location || '',
        nickName: user?.nickName || '@' + user?.displayName.replace(/\s/g, ''),
      },
      onSubmit: async (values) => {
        await updateUser(user?.id as string, { isNew: false, ...values })
      },
    })

  const next = () =>
    setStep((s) => {
      if (s === FormSteps.RESUME) {
        handleSubmit()
        return s
      }

      return s + 1
    })
  const back = () => setStep((s) => s - 1)

  const progress = 100 * (step / 5)

  if (!user?.isNew) {
    return <Redirect to="/profile" />
  }

  return (
    <Flex
      animate="visible"
      as={motion.div}
      direction="column"
      gridGap={[4, 6]}
      initial="hidden"
      key={2}
      marginX="auto"
      maxW="650px"
      minH="100vh"
      overflow="visible"
      p={[4, 6]}
      variants={wrapper}
    >
      <Heading
        as={motion.h1}
        color="primary.300"
        size="4xl"
        variants={fadeInFromLeft}
      >
        {t('new_user.heading')}
      </Heading>
      <Text
        as={motion.p}
        color="secondary.300"
        fontSize="lg"
        variants={fadeInFromLeft}
      >
        {t('new_user.welcome')}
      </Text>
      <Text as={motion.p} fontSize="lg" variants={fadeInFromLeft}>
        {t('new_user.body')}
      </Text>
      <motion.div variants={fadeInFromLeft}>
        <Progress
          borderRadius="md"
          colorScheme="primary"
          sx={{ '> div': { transition: '300ms' } }}
          value={progress}
        />
      </motion.div>
      <AnimatePresence initial mode="wait">
        <Flex
          as={motion.div}
          direction="column"
          gridGap={[4, 6]}
          key={step}
          variants={fadeIn}
        >
          {step === FormSteps.NICKNAME && (
            <NickNameCheck
              handleNext={next}
              setFieldValue={setFieldValue}
              value={values.nickName}
            />
          )}
          {step === FormSteps.DISPLAY_NAME && (
            <DisplayName
              handleBack={back}
              handleNext={next}
              onChange={handleChange}
              value={values.displayName}
            />
          )}
          {step === FormSteps.SPECIAL_DATES && (
            <SpecialDates
              dates={values.dates}
              handleBack={back}
              handleNext={next}
              setFieldValue={setFieldValue}
            />
          )}
          {step === FormSteps.LOCATION && (
            <Location
              handleBack={back}
              handleNext={next}
              onChange={handleChange}
              value={values.location}
            />
          )}
          {step === FormSteps.DETAILS && (
            <DefaultDetails
              details={values.details}
              handleBack={back}
              handleNext={next}
              setFieldValue={setFieldValue}
            />
          )}
          {step === FormSteps.RESUME && (
            <Resume
              handleBack={back}
              handleNext={next}
              isSubmitting={isSubmitting}
              onSetStep={setStep}
              values={values}
            />
          )}
        </Flex>
      </AnimatePresence>
    </Flex>
  )
}
