import { useCallback, useContext, useEffect } from 'react'

import { DataContext } from 'context/DataContext'
import { uuid } from 'lib/uuid'

type UseResource<T> = {
  data?: T
  loading: boolean
  deleteItem: () => void
}

export const useResource = <T>(path: string): UseResource<T> => {
  const {
    subscribe,
    unsubscribe,
    read,
    delete: deleteResource,
  } = useContext(DataContext)

  const { item, loading } = read<T>(path)

  const deleteItem = useCallback(() => {
    deleteResource(path)
  }, [deleteResource, path])

  useEffect(() => {
    const uid = uuid()

    subscribe(path, uid)

    return () => unsubscribe(path, uid)
  }, []) // eslint-disable-line

  return { data: item, deleteItem, loading }
}
