import { Button, IconButton } from '@chakra-ui/button'
import { DeleteIcon, PlusSquareIcon } from '@chakra-ui/icons'
import { Input } from '@chakra-ui/input'
import { Flex, Text, VStack } from '@chakra-ui/layout'
import { Menu, MenuButton, MenuItem, MenuList } from '@chakra-ui/menu'
import { ChangeEvent } from 'react'
import { useTranslation } from 'react-i18next'

import { AnimatedList } from 'components/Animator/AnimatedList'
import { WishDetail, WishDetailsType } from 'types'

type Props = {
  handleNext: () => void
  handleBack: () => void
  details: Array<WishDetail>
  setFieldValue: (name: string, value: unknown) => void
}

export const DefaultDetails = ({
  handleNext,
  handleBack,
  details,
  setFieldValue,
}: Props): JSX.Element => {
  const { t } = useTranslation()

  const handleSelect = (key: string) => {
    setFieldValue('details', [
      ...details,
      {
        content: '',
        id: key,
        type: key,
      },
    ])
  }

  const handleEditContent = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target

    setFieldValue(name, value)
  }

  const handleRemove = (index: number) => {
    setFieldValue(
      'details',
      details.filter((d, i) => i !== index),
    )
  }

  const options = Object.entries(WishDetailsType).filter(
    (keyValue) => !details.find(({ id }) => id === keyValue[0]),
  )

  return (
    <>
      <Text color="primary.300">{t('new_user.details.body')}</Text>
      <VStack>
        <AnimatedList items={details}>
          {(detail, index) => {
            const { id, type, content } = detail as WishDetail

            return (
              <Flex
                alignItems="center"
                gridGap={[2]}
                justifyContent="space-between"
                key={id}
                p={2}
                shadow="md"
                w="100%"
              >
                <Text w={110}>{t(`detail_name.${type}`)}</Text>
                <Input
                  flex={1}
                  name={`details[${index}].content`}
                  onChange={handleEditContent}
                  value={content}
                />

                <IconButton
                  aria-label="delete"
                  icon={<DeleteIcon />}
                  onClick={() => handleRemove(index)}
                  size="sm"
                />
              </Flex>
            )
          }}
        </AnimatedList>
        <Menu>
          <MenuButton
            as={Button}
            colorScheme="primary"
            disabled={options.length === 0}
            rightIcon={<PlusSquareIcon />}
            w="100%"
          >
            {t('new_user.details.add')}
          </MenuButton>
          <MenuList>
            {options.map(([key, value]) => {
              return (
                <MenuItem
                  key={key}
                  onClick={() => handleSelect(key)}
                  value={key}
                >
                  {t(`detail_name.${value}`)}
                </MenuItem>
              )
            })}
          </MenuList>
        </Menu>
      </VStack>
      <Flex justifyContent="space-between">
        <Button colorScheme="secondary" onClick={handleBack}>
          {t('back')}
        </Button>
        <Button colorScheme="primary" onClick={handleNext}>
          {t('next')}
        </Button>
      </Flex>
    </>
  )
}
