import { Button } from '@chakra-ui/button'
import { useBoolean } from '@chakra-ui/hooks'
import { Input } from '@chakra-ui/input'
import { Text } from '@chakra-ui/layout'
import { FormikErrors } from 'formik'
import { ChangeEvent, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Translate } from 'components/Translate'
import { useLoggedUser } from 'hooks/useLoggedUser'
import { checkNicknameAvailability } from 'services/firebase/user'

type Props = {
  setFieldValue: (
    field: string,
    value: string,
    shouldValidate?: boolean | undefined,
  ) =>
    | Promise<void>
    | Promise<
        FormikErrors<{
          nickName: string
        }>
      >
  value: string
  handleNext: () => void
}

export const NickNameCheck = ({
  setFieldValue,
  value,
  handleNext,
}: Props): JSX.Element => {
  const { t } = useTranslation()
  const { user } = useLoggedUser()
  const [isAvailable, setIsAvailable] = useState<boolean | undefined>(
    user?.nickName ? true : undefined,
  )
  const [checkingAvailability, { on, off }] = useBoolean()

  const checkAvailability = async () => {
    on()
    const isAvailable = await checkNicknameAvailability(
      value,
      user?.id as string,
    )

    setIsAvailable(isAvailable)
    off()
  }

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value.replace(/\s|@/g, '')
    setIsAvailable(undefined)
    setFieldValue('nickName', '@' + newValue)
  }

  const shouldShowResult = typeof isAvailable === 'boolean'

  return (
    <>
      <Text color="primary.300" lineHeight={1.2}>
        {t('new_user.nickname.body')}
      </Text>
      <Input
        disabled={checkingAvailability}
        name="nickName"
        onChange={handleChange}
        value={value}
      />
      {shouldShowResult && (
        <Text>
          {isAvailable ? (
            <Translate
              i18nKey="new_user.nickname.available"
              values={{ name: value }}
            />
          ) : (
            t('new_user.nickname.not_available')
          )}
        </Text>
      )}

      {isAvailable ? (
        <Button colorScheme="primary" onClick={handleNext}>
          {t('next')}
        </Button>
      ) : (
        <Button isLoading={checkingAvailability} onClick={checkAvailability}>
          {t('new_user.nickname.check')}
        </Button>
      )}
    </>
  )
}
