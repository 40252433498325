import { AnimatePresence } from 'framer-motion'
import { useMemo } from 'react'
import { Switch, useLocation } from 'react-router-dom'

import { uuid } from 'lib/uuid'

type Props = {
  children: React.ReactNode
  lock?: boolean
}

export const AnimatedSwitch = ({ children, lock }: Props): JSX.Element => {
  const lockKey = useMemo(() => uuid(), [])
  const location = useLocation()

  return (
    <AnimatePresence initial={false} mode="wait">
      <Switch key={lock ? lockKey : location.pathname} location={location}>
        {children}
      </Switch>
    </AnimatePresence>
  )
}
