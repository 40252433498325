import { getAuth } from 'firebase/auth'
import { useEffect, useState } from 'react'

import { logOut, signinWithGoogle } from 'services/firebase'
import {
  createUserIfMisssing,
  subscribeToUserSnapshot,
} from 'services/firebase/user'
import { User } from 'types'

type UseUserContext = {
  loading: boolean
  user?: User
  isLogged: boolean
  handleLogin: () => Promise<void>
  handleLogout: () => Promise<void>
}

export const useUserContext = (): UseUserContext => {
  const [loadingUser, setLoadingUser] = useState(true)
  const [user, setUser] = useState<User>()
  const isLogged = Boolean(user)

  useEffect(() => {
    let unsuscribeFromSnapshotUpdates: () => void

    const unregister = getAuth().onAuthStateChanged(async (authUser) => {
      if (!authUser) {
        unsuscribeFromSnapshotUpdates?.()
        setUser(undefined)
        setLoadingUser(false)
        logOut()
        return
      }

      try {
        setLoadingUser(true)
        createUserIfMisssing(authUser)
        unsuscribeFromSnapshotUpdates = subscribeToUserSnapshot(
          authUser.uid,
          (user) => {
            setUser(user)
            setLoadingUser(false)
          },
        )
      } catch (e) {
        logOut()
        setUser(undefined)
        setLoadingUser(false)
      }
    })

    return () => {
      if (unsuscribeFromSnapshotUpdates) unsuscribeFromSnapshotUpdates()
      unregister()
    }
  }, [])

  const handleLogin = async (): Promise<void> => {
    await signinWithGoogle()
  }

  const handleLogout = async (): Promise<void> => {
    await logOut()
  }

  return {
    handleLogin,
    handleLogout,
    isLogged,
    loading: loadingUser,
    user,
  }
}
