import { useToast as useChakraToast, UseToastOptions } from '@chakra-ui/toast'
import { useMemo } from 'react'

const common: UseToastOptions = {
  duration: 4000,
  position: 'bottom-right',
  variant: 'left-accent',
}

type UseToast = {
  error: (msg: string) => void
  success: (msg: string) => void
}

export const useToast = (): UseToast => {
  const toast = useChakraToast()

  const toaster = useMemo(
    () => ({
      error: (msg: string) => toast({ ...common, status: 'error', title: msg }),
      success: (msg: string) =>
        toast({ ...common, status: 'success', title: msg }),
    }),
    [toast],
  )
  return toaster
}
