import { useFriendsUpdates } from 'hooks/useFriendsUpdates'
import { UpdateType } from 'types'

import { FriendUpdateWishAdded } from './WishAdded'
import { FriendUpdateWishOwn } from './WishOwned'
import { FriendUpdateWrapper } from './Wrapper'

export const FriendsUpdates = (): JSX.Element => {
  const { updates, loading } = useFriendsUpdates()

  if (loading)
    return (
      <>
        {Array(6)
          .fill(null)
          .map((u, index) => (
            <FriendUpdateWrapper key={index} />
          ))}
      </>
    )

  return (
    <>
      {updates?.map((update, index) => {
        const { type } = update

        switch (type) {
          case UpdateType.ADD_WISH:
            return <FriendUpdateWishAdded key={index} update={update} />
          case UpdateType.OWN_WISH:
            return <FriendUpdateWishOwn key={index} update={update} />
          default:
            throw new Error('Update type not supported, check ' + type)
        }
      })}
    </>
  )
}
