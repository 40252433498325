import {
  arrayRemove,
  arrayUnion,
  Timestamp,
  writeBatch,
} from '@firebase/firestore'

import { uuid } from 'lib/uuid'
import { Notification, NotificationState, NotificationType, User } from 'types'

import { db, getNotificationNewRef, getUserRef } from '.'

export const addFriendRequest = async (
  from: User,
  target: string,
): Promise<void> => {
  const senderRef = getUserRef(from.id)
  const targetRef = getUserRef(target)
  const notificationsRef = getNotificationNewRef()

  const batch = writeBatch(db)

  batch.update(senderRef, {
    sentRequests: arrayUnion(target),
  })

  batch.update(targetRef, {
    receivedRequests: arrayUnion(from.id),
  })

  batch.set(notificationsRef, {
    date: Timestamp.now(),
    id: uuid(),
    meta: {
      displayName: from.displayName,
      href: '/friends',
      photoURL: from.photoURL,
    },
    state: NotificationState.NEW,
    type: NotificationType.FRIEND_REQUEST,
    userId: target,
  } as Notification)

  return batch.commit()
}

export const removeFriendRequest = async (
  from: string,
  target: string,
): Promise<void> => {
  const senderRef = getUserRef(from)
  const targetRef = getUserRef(target)

  const batch = writeBatch(db)

  batch.update(senderRef, {
    sentRequests: arrayRemove(target),
  })

  batch.update(targetRef, {
    receivedRequests: arrayRemove(from),
  })

  return batch.commit()
}

export const acceptFriendRequest = async (
  from: User, // the one who received the request
  to: string, // the one who sent it
): Promise<void> => {
  const since = Timestamp.now()
  const fromRef = getUserRef(from.id)
  const toRef = getUserRef(to)
  const notificationsRef = getNotificationNewRef()

  const batch = writeBatch(db)

  batch.update(fromRef, {
    friendsIds: arrayUnion(to),
    [`friendsSince.${to}`]: since,
    receivedRequests: arrayRemove(to),
  })

  batch.update(toRef, {
    friendsIds: arrayUnion(from.id),
    [`friendsSince.${from.id}`]: since,
    sentRequests: arrayRemove(from.id),
  })

  batch.set(notificationsRef, {
    date: Timestamp.now(),
    id: uuid(),
    meta: {
      displayName: from.displayName,
      href: `/friends/${from.id}`,
      photoURL: from.photoURL,
    },
    state: NotificationState.NEW,
    type: NotificationType.FRIEND_REQUEST_ACCEPTED,
    userId: to,
  } as Notification)

  return batch.commit()
}

export const deleteFriendship = async (
  from: string,
  to: string,
): Promise<void> => {
  const fromRef = getUserRef(from)
  const toRef = getUserRef(to)

  const batch = writeBatch(db)

  batch.update(fromRef, {
    friendsIds: arrayRemove(to),
  })

  batch.update(toRef, {
    friendsIds: arrayRemove(from),
  })

  return batch.commit()
}
