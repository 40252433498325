import React from 'react'

export type DataContextValue = {
  data: Record<string, unknown>
  subscribe: (resource: string, uid: string) => void
  unsubscribe: (resource: string, uid: string) => void
  delete: (resource: string) => void
  read: <T>(resource: string) => { loading: boolean; item?: T }
}

export const DataContext = React.createContext<DataContextValue>({
  data: {},
  delete: () => null,
  read: () => ({ loading: false }),
  subscribe: () => null,
  unsubscribe: () => null,
})
