import { Button, IconButton } from '@chakra-ui/button'
import { EditIcon } from '@chakra-ui/icons'
import { Flex, Heading, Text, Wrap } from '@chakra-ui/layout'
import { Tag } from '@chakra-ui/tag'
import { Dispatch, SetStateAction } from 'react'
import { useTranslation } from 'react-i18next'

import { UNDEFINED_LOCATION } from 'constantValues'
import { SpecialDate, WishDetail } from 'types'

import { FormSteps } from './common'

type Props = {
  handleNext: () => void
  handleBack: () => void
  onSetStep: Dispatch<SetStateAction<number>>
  values: {
    nickName: string
    displayName: string
    dates: Array<SpecialDate>
    location: string
    details: Array<WishDetail>
  }
  isSubmitting: boolean
}

export const Resume = ({
  handleBack,
  handleNext,
  values,
  onSetStep,
  isSubmitting,
}: Props): JSX.Element => {
  const { t } = useTranslation()

  return (
    <>
      <Heading size="md">{t('new_user.allset')}</Heading>
      <Text color="primary.300">{t('new_user.review')}</Text>

      <Heading size="xs">{t('new_user.tagname_heading')}</Heading>
      <Wrap>
        <IconButton
          aria-label="edit"
          colorScheme="secondary"
          icon={<EditIcon />}
          isDisabled={isSubmitting}
          onClick={() => onSetStep(FormSteps.NICKNAME)}
          size="sm"
        />
        <Tag colorScheme="primary">{values.nickName}</Tag>
      </Wrap>

      <Heading size="xs">{t('new_user.display_name_heading')}</Heading>
      <Wrap>
        <IconButton
          aria-label="edit"
          colorScheme="secondary"
          icon={<EditIcon />}
          isDisabled={isSubmitting}
          onClick={() => onSetStep(FormSteps.DISPLAY_NAME)}
          size="sm"
        />
        <Tag colorScheme="primary">{values.displayName}</Tag>
      </Wrap>

      <Heading size="xs">{t('new_user.dates_heading')}</Heading>
      <Wrap>
        <IconButton
          aria-label="edit"
          colorScheme="secondary"
          icon={<EditIcon />}
          isDisabled={isSubmitting}
          onClick={() => onSetStep(FormSteps.SPECIAL_DATES)}
          size="sm"
        />
        {values.dates.map((specialDate) => (
          <Tag colorScheme="primary" key={specialDate.id}>
            <Text as="strong" pr={1}>
              {t('date.short', { date: specialDate.date })}
            </Text>
            {specialDate.name}
          </Tag>
        ))}
      </Wrap>

      <Heading size="xs">{t('new_user.location_heading')}</Heading>
      <Wrap>
        <IconButton
          aria-label="edit"
          colorScheme="secondary"
          icon={<EditIcon />}
          isDisabled={isSubmitting}
          onClick={() => onSetStep(FormSteps.LOCATION)}
          size="sm"
        />
        <Tag colorScheme="primary">{values.location || UNDEFINED_LOCATION}</Tag>
      </Wrap>

      <Heading size="xs">{t('new_user.details_heading')}</Heading>
      <Wrap>
        <IconButton
          aria-label="edit"
          colorScheme="secondary"
          icon={<EditIcon />}
          isDisabled={isSubmitting}
          onClick={() => onSetStep(FormSteps.DETAILS)}
          size="sm"
        />
        {values.details.map((detail) => (
          <Tag colorScheme="primary" key={detail.id}>
            <Text as="strong" pr={1}>
              {t(`detail_name.${detail.type}`)}
            </Text>
            {detail.content}
          </Tag>
        ))}
      </Wrap>

      <Flex justifyContent="space-between">
        <Button
          colorScheme="secondary"
          isDisabled={isSubmitting}
          onClick={handleBack}
        >
          {t('back')}
        </Button>
        <Button
          colorScheme="primary"
          isLoading={isSubmitting}
          onClick={handleNext}
        >
          {t('new_user.finish')}
        </Button>
      </Flex>
    </>
  )
}
