export const currencyFormatter = new Intl.NumberFormat('es-ES', {
  currency: 'EUR',
  style: 'currency',
})

export const dateFormatterOptions: Intl.DateTimeFormatOptions = {
  day: 'numeric',
  month: 'long',
  year: 'numeric',
}

export const shortDateFormatterOptions: Intl.DateTimeFormatOptions = {
  day: 'numeric',
  month: 'short',
}

export const dayDateFormatterOptions: Intl.DateTimeFormatOptions = {
  day: 'numeric',
  month: 'numeric',
}

export const timestampFormatterOptions: Intl.DateTimeFormatOptions = {
  day: '2-digit',
  hour: '2-digit',
  hour12: true,
  minute: '2-digit',
  month: '2-digit',
  year: '2-digit',
}
