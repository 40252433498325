import {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState
} from 'react'
import { useHistory } from 'react-router-dom'

type Props = {
  children: React.ReactNode
}

export type BrowserHistoryContext = {
  pathname: string
  prevPathname: string
  stack: string[]
  depth: number
  prevDepth: number
  depthDelta: number
  push: (path: string) => void
}

const defaultState = {
  depth: 0,
  depthDelta: 0,
  pathname: document.location.pathname,
  prevDepth: 0,
  prevPathname: document.location.pathname,
  stack: [] as string[],
}

const defaultValue = {
  ...defaultState,
  push: (() => {
    return
  }) as (s: string) => void,
}

export const Context = createContext(defaultValue)

export const useBrowserHistory = (): BrowserHistoryContext => {
  const context = useContext(Context)

  return context
}

const getDepth = (str: string): number => str.split('/').length

export const BrowserHistoryProvider = ({ children }: Props): JSX.Element => {
  const history = useHistory()
  const [state, setState] = useState(defaultState)

  const push = useCallback(
    (pathname: string) => {
      setState((prev) => {
        if (prev.pathname !== pathname) {
          const prevPathname = prev.pathname || pathname
          const depth = getDepth(pathname)
          const prevDepth = getDepth(prevPathname)

          return {
            depth,
            depthDelta: depth - prevDepth,
            pathname,
            prevDepth,
            prevPathname,
            stack: [pathname, ...prev.stack],
          }
        } else {
          return prev
        }
      })

      history.push(pathname)
    },
    [history],
  )

  const value = useMemo(() => ({ push, ...state }), [state, push])

  return <Context.Provider value={value}>{children}</Context.Provider>
}
