import { HStack, Text, VStack } from '@chakra-ui/layout'
import { useTranslation } from 'react-i18next'

import { ModalAddWish } from 'components/ModalAddWish'
import { WishList } from 'components/WishList'
import { useLoggedUser } from 'hooks/useLoggedUser'
import { User } from 'types'

export const Wishes = (): JSX.Element => {
  const { t } = useTranslation()
  const { user } = useLoggedUser()

  return (
    <VStack
      height="calc(100vh - 64px)"
      marginX="auto"
      maxW={1200}
      overflowY="auto"
      pb={4}
      px={[4, 4, 4, 4, 0]}
      w="100%"
    >
      <HStack as="header" justifyContent="space-between" py={4} w="100%">
        <Text>{t('wishes.heading')}</Text>
        <ModalAddWish />
      </HStack>
      <WishList user={user as User} />
    </VStack>
  )
}
