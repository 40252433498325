import { Button, IconButton } from '@chakra-ui/button'
import { useColorModeValue } from '@chakra-ui/color-mode'
import { useDisclosure } from '@chakra-ui/hooks'
import { AddIcon, DeleteIcon } from '@chakra-ui/icons'
import { Flex, HStack, VStack } from '@chakra-ui/layout'
import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay
} from '@chakra-ui/modal'
import { FormControl, FormHelperText, FormLabel, Input } from '@chakra-ui/react'
import { Select } from '@chakra-ui/select'
import { Spinner } from '@chakra-ui/spinner'
import { Textarea } from '@chakra-ui/textarea'
import { useFormik } from 'formik'
import { ChangeEvent, useCallback, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory, useLocation } from 'react-router'

import { useLoggedUser } from 'hooks/useLoggedUser'
import { useToast } from 'hooks/useToast'
import { uuid } from 'lib/uuid'
import { unfurl } from 'services/firebase'
import { createWish } from 'services/firebase/wish'
import { WishCreateInput, WishDetail, WishDetailsType } from 'types'

import { AnimatedList } from './Animator/AnimatedList'
import { ImageEditor } from './ImageEditor'

export const ModalAddWish = (): JSX.Element => {
  const debounce = useRef<ReturnType<typeof setTimeout>>()
  const { t } = useTranslation()
  const urlInput = useRef<HTMLInputElement>(null)
  const { search, pathname } = useLocation()
  const { replace } = useHistory()
  const toast = useToast()
  const { user } = useLoggedUser()
  const [unfurling, setUnfurling] = useState(false)
  const { isOpen, onOpen, onClose } = useDisclosure()

  const handleClose = () => {
    resetForm()
    onClose()
  }

  const { handleSubmit, handleChange, values, resetForm, setFieldValue } =
    useFormik({
      initialValues: {
        defaultPhotoURL: '',
        description: '',
        details: [],
        name: '',
        photoPosition: { x: 50, y: 50 },
        photoURL: '',
        price: 0,
        url: '',
      } as Omit<WishCreateInput, 'userId'>,
      onSubmit: async (values) => {
        if (!user) return alert('Error! no user logged')
        if (!values.url.length) return alert('url field should not be empty')
        try {
          await createWish(user, values)

          toast.success(t('modal_add_wish.toast_submit_success'))
          handleClose()
        } catch (e) {
          toast.error(t('modal_add_wish.toast_submit_error'))
        }
      },
    })

  const updateWithUnfurledData = useCallback(
    async (url: string) => {
      setUnfurling(true)

      try {
        const result = await unfurl(url)

        toast.success(t('modal_add_wish.toast_unfurl_success'))

        const { title, description, price, photoURL } = result.data

        if (!values.name) setFieldValue('name', title)
        if (!values.description)
          setFieldValue('description', description || description)
        if (!values.photoURL) {
          setFieldValue('defaultPhotoURL', photoURL)
          setFieldValue('photoURL', photoURL)
        }
        if (!values.price) setFieldValue('price', price)

        setUnfurling(false)
      } catch {
        toast.error(t('modal_add_wish.toast_unfurl_error'))

        setUnfurling(false)
      }
    },
    [
      setFieldValue,
      t,
      toast,
      values.description,
      values.name,
      values.photoURL,
      values.price,
    ],
  )

  const handleUrlChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const { value: rawValue } = event.target

      const value = rawValue.trim().split(' ').reverse()[0]

      if (value.length > 8) {
        const hasTimeout = !debounce.current

        if (hasTimeout) {
          clearTimeout(debounce.current)
        }

        debounce.current = setTimeout(() => {
          updateWithUnfurledData(value)
        }, hasTimeout ? 1000 : 0)
      }

      setFieldValue('url', value)
    },
    [setFieldValue, updateWithUnfurledData],
  )

  const handleAddDetail = () => {
    const defaultValue = user?.details?.find(
      ({ type }) => type === WishDetailsType.SIZE,
    )

    setFieldValue('details', [
      ...values.details,
      {
        content: defaultValue?.content || '',
        id: uuid(),
        type: WishDetailsType.SIZE,
      },
    ])
  }

  const handleRemoveDetail = (detailId: string) => {
    setFieldValue(
      'details',
      values.details.filter((detail) => detail.id !== detailId),
    )
  }

  const handleChangeDetailType = (event: ChangeEvent<HTMLSelectElement>) => {
    const targetField = event.target.name.replace('type', 'content')

    const defaultValue = user?.details?.find(
      ({ type }) => type === event.target.value,
    )

    setFieldValue(targetField, defaultValue?.content || '')

    handleChange(event)
  }

  useEffect(() => {
    const params = new URLSearchParams(search)
    const wishUrl = params.get('wishUrl')

    if (wishUrl) {
      onOpen()

      handleUrlChange({
        target: {
          value: wishUrl,
        },
      } as unknown as ChangeEvent<HTMLInputElement>)
    }

    replace(pathname)
  }, [handleUrlChange, onOpen, pathname, replace, search])

  return (
    <>
      <Button onClick={onOpen}>{t('modal_add_wish.open')}</Button>

      <Modal isOpen={isOpen} onClose={handleClose}>
        <ModalOverlay />
        <ModalContent
          mb={[0, '3.75em']}
          minHeight={['100vh', 'auto']}
          mt={[0, '3.75em']}
        >
          <ModalHeader
            color={useColorModeValue('primary.400', 'primary.200')}
            fontFamily="heading"
            fontWeight={100}
          >
            {t('modal_add_wish.heading')}
          </ModalHeader>
          <ModalCloseButton />
          <form onSubmit={handleSubmit}>
            <ModalBody>
              <VStack mt={4} spacing={6}>
                <ImageEditor
                  defaultPhotoURL={values.defaultPhotoURL}
                  loading={unfurling}
                  photoPosition={values.photoPosition}
                  photoURL={values.photoURL}
                  setFieldValue={setFieldValue}
                  uploadDisabled={values.url.length === 0}
                />
                <FormControl id="url">
                  <FormLabel>{t('modal_edit_wish.link_label')}</FormLabel>
                  <HStack>
                    <Input
                      onChange={handleUrlChange}
                      ref={urlInput}
                      type="text"
                      value={values.url}
                    />
                    {unfurling && <Spinner />}
                  </HStack>
                  <FormHelperText>
                    {t('modal_edit_wish.link_helper')}
                  </FormHelperText>
                </FormControl>

                <FormControl id="name" isRequired>
                  <FormLabel>{t('modal_edit_wish.name_label')}</FormLabel>
                  <Input
                    onChange={handleChange}
                    type="text"
                    value={values.name}
                  />
                  <FormHelperText>
                    {t('modal_edit_wish.name_helper')}
                  </FormHelperText>
                </FormControl>

                <FormControl id="price">
                  <FormLabel>{t('modal_edit_wish.price_label')}</FormLabel>
                  <Input
                    onChange={handleChange}
                    type="number"
                    value={values.price}
                  />
                  <FormHelperText>
                    {t('modal_edit_wish.price_helper')}
                  </FormHelperText>
                </FormControl>

                <FormControl id="details">
                  <Flex justifyContent="space-between" w="100%">
                    <FormLabel>{t('modal_edit_wish.details_label')}</FormLabel>
                    <Button
                      onClick={handleAddDetail}
                      rightIcon={<AddIcon />}
                      size="xs"
                    >
                      {t('add')}
                    </Button>
                  </Flex>

                  <VStack py={2} spacing={1}>
                    <AnimatedList items={values.details}>
                      {(detail, i) => {
                        const { id } = detail as WishDetail

                        return (
                          <Flex
                            gridGap={2}
                            justifyContent="space-between"
                            key={id}
                            w="100%"
                          >
                            <Select
                              flex={1}
                              name={`details[${i}].type`}
                              onChange={handleChangeDetailType}
                              size="xs"
                              value={values.details[i].type}
                              variant="filled"
                            >
                              {Object.entries(WishDetailsType).map(
                                ([key, value]) => {
                                  return (
                                    <option key={key} value={value}>
                                      {t(`detail_name.${value}`)}
                                    </option>
                                  )
                                },
                              )}
                            </Select>
                            <Input
                              flex={2}
                              name={`details[${i}].content`}
                              onChange={handleChange}
                              size="xs"
                              value={values.details[i].content}
                            />
                            <IconButton
                              aria-label="remove detail"
                              icon={<DeleteIcon />}
                              onClick={() => handleRemoveDetail(id)}
                              size="xs"
                              variant="secondary"
                            />
                          </Flex>
                        )
                      }}
                    </AnimatedList>
                  </VStack>
                </FormControl>

                <FormControl id="description">
                  <FormLabel>
                    {t('modal_edit_wish.description_label')}
                  </FormLabel>
                  <Textarea
                    onChange={handleChange}
                    resize="none"
                    value={values.description}
                  />
                </FormControl>
              </VStack>
            </ModalBody>
            <ModalFooter>
              <Button
                disabled={unfurling}
                mr={2}
                onClick={() => {
                  resetForm()
                }}
                variant="secondary"
              >
                {t('clear')}
              </Button>
              <Button disabled={unfurling} type="submit" variant="primary">
                {t('submit')}
              </Button>
            </ModalFooter>
          </form>
        </ModalContent>
      </Modal>
    </>
  )
}
