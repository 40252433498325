import { motion } from 'framer-motion'
import { Route } from 'react-router-dom'

import {
  BrowserHistoryContext,
  useBrowserHistory
} from 'components/BrowserHistoryProvider'

type Props = {
  exact?: boolean
  children: React.ReactNode
  path?: string
} & Record<string, unknown>

const transition = {
  dureation: 175,
}

const getSection = (path: string): string => path.split('/')[1]

const sections = ['profile', 'friends', 'wishes']

const getDirection = (from: string, to: string): number => {
  const fromSection = getSection(from)
  const toSection = getSection(to)

  const fromIndex = sections.indexOf(fromSection)
  const toIndex = sections.indexOf(toSection)

  if (fromIndex === toIndex) return 0
  return fromIndex > toIndex ? 1 : -1
}

const variants = {
  exit: ({ depthDelta, pathname, prevPathname }: BrowserHistoryContext) => {
    return {
      opacity: 0,
      scale: depthDelta > 0 ? 2 : depthDelta < 0 ? 0.5 : 1,
      x: 100 * getDirection(prevPathname, pathname),
    }
  },
  initial: ({ pathname, prevPathname }: BrowserHistoryContext) => {
    return {
      opacity: 0,
      scale: 1,
      x: -100 * getDirection(prevPathname, pathname),
    }
  },
  show: () => ({
    opacity: 1,
    scale: 1,
    x: 0,
  }),
}

export const AnimatedRoute = ({
  children,
  exact,
  path,
  ...rest
}: Props): JSX.Element => {
  const browserHistory = useBrowserHistory()

  return (
    <Route exact={exact} path={path} {...rest}>
      <motion.div
        animate="show"
        custom={browserHistory}
        exit="exit"
        initial="initial"
        style={{ width: '100% ' }}
        transition={transition}
        variants={variants}
      >
        {children}
      </motion.div>
    </Route>
  )
}
