import { InputGroup, useBoolean } from '@chakra-ui/react'
import { getDownloadURL } from 'firebase/storage'
import { ChangeEvent, ReactNode, useRef } from 'react'

import { uploadPhoto } from 'services/firebase/files'

type FileUploadProps = {
  children?:
    | ReactNode
    | ((openUpload: () => void, loading: boolean) => JSX.Element)
  name: string
  setFieldValue: (field: string, value: unknown) => void
}

export const InputImage = ({
  children,
  setFieldValue,
  name,
}: FileUploadProps): JSX.Element => {
  const [loading, { on, off }] = useBoolean(false)
  const inputRef = useRef<HTMLInputElement | null>(null)

  const handleClick = () => {
    inputRef.current?.click()
  }
  const handleChange = async (e: ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0]

    if (file) {
      on()
      const result = await uploadPhoto(file)

      const photoURL = await getDownloadURL(result.ref)

      setFieldValue(name, photoURL)
      off()
    }
  }

  const isRenderProp = typeof children === 'function'

  return (
    <InputGroup
      cursor={isRenderProp ? 'auto' : 'pointer'}
      flex={0}
      onClick={isRenderProp ? undefined : handleClick}
    >
      <input
        accept="image/png, image/gif, image/jpeg"
        hidden
        onChange={handleChange}
        ref={inputRef}
        type={'file'}
      />
      <>{isRenderProp ? children(handleClick, loading) : children}</>
    </InputGroup>
  )
}
