import { use } from 'i18next'
import I18nextBrowserLanguageDetector from 'i18next-browser-languagedetector'
import { initReactI18next } from 'react-i18next'

import {
  dateFormatterOptions,
  dayDateFormatterOptions,
  shortDateFormatterOptions,
  timestampFormatterOptions,
} from 'lib/formatter'

import en from './en.json'
import es from './es.json'

const debug = Boolean(process.env.NODE_ENV === 'development')

use(initReactI18next)
  .use(I18nextBrowserLanguageDetector)
  .init({
    debug,
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false,
      format: (value, format, lng) => {
        if (format === 'DATE') {
          const asDate = value instanceof Date ? value : value.toDate()

          return new Intl.DateTimeFormat(lng, dateFormatterOptions).format(
            asDate,
          )
        }

        if (format === 'DAY_DATE') {
          const asDate = value instanceof Date ? value : value.toDate()

          return new Intl.DateTimeFormat(lng, dayDateFormatterOptions).format(
            asDate,
          )
        }

        if (format === 'SHORT_DATE') {
          const asDate = value instanceof Date ? value : value.toDate()

          return new Intl.DateTimeFormat(lng, shortDateFormatterOptions).format(
            asDate,
          )
        }

        if (format === 'TIMESTAMP') {
          const asDate = value instanceof Date ? value : value.toDate()

          return new Intl.DateTimeFormat(lng, timestampFormatterOptions).format(
            asDate,
          )
        }

        if (format === 'CURRENCY') {
          return new Intl.NumberFormat('es-ES', {
            currency: 'EUR',
            style: 'currency',
          }).format(value)
        }

        return value
      },
    },
    resources: {
      en,
      es,
    },
  })
