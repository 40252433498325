import { Heading, SimpleGrid, Text, VStack, Wrap } from '@chakra-ui/layout'
import { useBreakpointValue } from '@chakra-ui/media-query'
import { HStack } from '@chakra-ui/react'
import { Tab, TabList, TabPanel, TabPanels, Tabs } from '@chakra-ui/tabs'
import { Tag } from '@chakra-ui/tag'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router'

import { FriendsUpdates } from 'components/FriendUpdates'
import { IncomingDateButton } from 'components/IncomingDateButton'
import { ModalDateFriend } from 'components/ModalDateFriend'
import { ModalEditUser } from 'components/ModalEditUser'
import { UserProfile } from 'components/UserProfile'
import { useLoggedUser } from 'hooks/useLoggedUser'
import { useSpecialDates } from 'hooks/useSpecialDates'
import { SpecialDateWithUsers, User } from 'types'

enum WrapperType {
  FULL,
  LONGTAB,
  TAB,
}

const breakpointValues = {
  base: WrapperType.TAB,
  lg: WrapperType.FULL,
  md: WrapperType.LONGTAB,
}

const ContentWrapper = ({ children }: { children: React.ReactNode }) => {
  const { t } = useTranslation()
  const breakpoint = useBreakpointValue(breakpointValues)

  switch (breakpoint) {
    case WrapperType.FULL:
      return (
        <HStack alignItems="start" spacing={4} w="100%">
          {children}
        </HStack>
      )
    default:
      return (
        <Tabs
          alignSelf="start"
          colorScheme="primary"
          defaultIndex={1}
          width="100%"
        >
          <TabList>
            <Tab>
              {t(
                breakpoint === WrapperType.LONGTAB
                  ? 'profile.heading.incoming_dates'
                  : 'profile.heading.incoming_dates_short',
              )}
            </Tab>
            <Tab>
              {t(
                breakpoint === WrapperType.LONGTAB
                  ? 'profile.heading.friend_updates'
                  : 'profile.heading.friend_updates_short',
              )}
            </Tab>
            <Tab>
              {t(
                breakpoint === WrapperType.LONGTAB
                  ? 'profile.heading.you'
                  : 'profile.heading.you_short',
              )}
            </Tab>
          </TabList>
          <TabPanels p={0}>
            {(children as Array<React.ReactChild>)?.map((child, i) => {
              return <TabPanel key={i}>{child}</TabPanel>
            })}
          </TabPanels>
        </Tabs>
      )
  }
}

export const Profile = (): JSX.Element => {
  const { t } = useTranslation()
  const { user } = useLoggedUser()
  const { push } = useHistory()
  const [selected, setSelected] = useState<SpecialDateWithUsers>()
  const breakpoint = useBreakpointValue(breakpointValues)

  const { specialDates, isLoadingSpecialDates } = useSpecialDates(
    user?.id as string,
  )

  const unselect = () => setSelected(undefined)

  const handleIncomingDateClick = (date: SpecialDateWithUsers) => {
    if (date.users.length === 1) {
      push(`/friends/${date.users[0].id}`)
      return
    }

    setSelected(date)
  }

  const showHeaders = breakpoint === WrapperType.FULL

  return (
    <>
      <ModalDateFriend onClose={unselect} specialDate={selected} />
      <VStack
        alignItems="center"
        marginX="auto"
        maxW={1200}
        px={[2, 4, 4, 4, 0]}
        py={[2, 6]}
        spacing={6}
        w="100%"
      >
        <UserProfile user={user} />
        <ContentWrapper>
          <VStack>
            {showHeaders && (
              <Heading size="xs">{t('profile.heading.incoming_dates')}</Heading>
            )}
            {isLoadingSpecialDates
              ? Array(6)
                .fill(null)
                .map((x, i) => {
                  return <IncomingDateButton isLoading key={i} />
                })
              : specialDates.map((sD) => {
                return (
                  <IncomingDateButton
                    isLoading={false}
                    key={sD.id}
                    onClick={handleIncomingDateClick}
                    specialDate={sD}
                  />
                )
              })}
          </VStack>
          <VStack flex={1}>
            {showHeaders && (
              <Heading size="xs">{t('profile.heading.friend_updates')}</Heading>
            )}
            <FriendsUpdates />
          </VStack>
          <VStack w={{ base: '100%', lg: 60 }}>
            {showHeaders && (
              <Heading size="xs">{t('profile.heading.you')}</Heading>
            )}
            <Heading
              as="h6"
              color="gray.600"
              fontSize="xs"
              textAlign="left"
              w="100%"
            >
              {t('profile.you.heading_special_dates')}
            </Heading>
            <SimpleGrid
              columns={{ base: 1, lg: 1, md: 3 }}
              gap={1}
              py={2}
              w="100%"
            >
              {user?.dates?.map((specialDate) => {
                return (
                  <Tag key={specialDate.id} w="100%">
                    <Text as="strong">
                      {t('date.short', { date: specialDate.date })}
                    </Text>
                    :<Text ml={1}>{specialDate.name}</Text>
                  </Tag>
                )
              })}
            </SimpleGrid>
            <Heading
              as="h6"
              color="gray.600"
              fontSize="xs"
              textAlign="left"
              w="100%"
            >
              {t('profile.you.heading_details')}
            </Heading>
            <Wrap py={2}>
              {user?.details?.map((detail) => {
                return (
                  <Tag
                    colorScheme="secondary"
                    key={detail.id}
                    wordBreak="break-word"
                  >
                    {t(`detail_name.${detail.type}`)}:
                    <Text ml={1}>{detail.content}</Text>
                  </Tag>
                )
              })}
            </Wrap>
            <ModalEditUser user={user as User} />
          </VStack>
        </ContentWrapper>
      </VStack>
    </>
  )
}
