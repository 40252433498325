import { IconButton } from '@chakra-ui/button'
import { DeleteIcon } from '@chakra-ui/icons'
import { Input } from '@chakra-ui/input'
import { Flex } from '@chakra-ui/layout'
import { Timestamp } from '@firebase/firestore'
import { ChangeEvent } from 'react'
import { useTranslation } from 'react-i18next'
import { FaGift } from 'react-icons/fa'

import { DayPicker } from 'components/DayPicker'
import { SpecialDate } from 'types'

type Props = {
  index: number
  specialDate: SpecialDate
  setFieldValue: (name: string, value: unknown) => void
  onRemove: (id: string) => void
  showGiftIcon?: boolean
}

export const GiftDate = ({
  index,
  specialDate,
  setFieldValue,
  onRemove,
  showGiftIcon = true,
}: Props): JSX.Element => {
  const { t } = useTranslation()
  const { date, name, id } = specialDate

  const handleDateChange = (date: Timestamp) => {
    setFieldValue(`dates[${index}].date`, date)
  }

  const handleNameChange = (event: ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value

    setFieldValue(`dates[${index}].name`, newValue)
  }

  const handleRemove = () => {
    onRemove(id)
  }

  return (
    <Flex
      alignItems="center"
      gridGap={[2]}
      justifyContent="space-between"
      p={2}
      shadow="md"
      w="100%"
    >
      {showGiftIcon && <FaGift />}
      <DayPicker onChange={handleDateChange} value={date} />

      <Input
        flex={1}
        name="name"
        onChange={handleNameChange}
        placeholder={t('modal_edit_profile.event_placeholder')}
        value={name}
      />

      <IconButton
        aria-label="delete"
        icon={<DeleteIcon />}
        onClick={handleRemove}
        size="sm"
      />
    </Flex>
  )
}
