import { SimpleGrid } from '@chakra-ui/layout'
import { Center, Text } from '@chakra-ui/react'
import { AnimatePresence } from 'framer-motion'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router'

import { useUserWishes } from 'hooks/useUserWishes'
import { User, Wish } from 'types'

import { CardWish } from './CardWish'

type Props = {
  user: User
  basePath?: string
}

const getList = (user: User, wishes: Wish[], loading: boolean): Array<Wish> => {
  if (!user.wishesIds) return []

  if (loading) return user?.wishesIds?.map((id) => ({ id } as Wish))

  return user.wishesIds.map((id) => {
    return wishes.find((wish) => wish.id === id) as Wish
  })
}

export const WishList = ({ user, basePath }: Props): JSX.Element => {
  const { t } = useTranslation()
  const { wishes, loading } = useUserWishes(user?.id as string)
  const { pathname } = useLocation()

  const list = getList(user, wishes as Wish[], loading).reverse()

  return (
    <>
      {(!wishes || wishes.length === 0) && !loading && (
        <Center color="primary.300" height={100} py={2} w="100%">
          <Text>{t('wishes.no_wishes')}</Text>
        </Center>
      )}
      <SimpleGrid columns={[1, 1, 2, 2, 3]} spacing={4} width="100%">
        <AnimatePresence>
          {list?.map((wish) => (
            <CardWish
              basePath={`${basePath || pathname}/`}
              isLoading={loading}
              key={wish?.id}
              wish={wish}
            />
          ))}
        </AnimatePresence>
      </SimpleGrid>
    </>
  )
}
