import { User } from 'types'

import { useResource } from './useResource'

type UseUser = {
  loading: boolean
  user?: User
}

export const useUser = (id?: string): UseUser => {
  const { data: user, loading } = useResource<User>(`user/${id}`)

  return { loading, user }
}
