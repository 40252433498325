import {
  addDoc,
  arrayRemove,
  arrayUnion,
  deleteField,
  getDoc,
  serverTimestamp,
  Timestamp,
  updateDoc,
  writeBatch,
} from '@firebase/firestore'

import { uuid } from 'lib/uuid'
import {
  InteractionType,
  Notification,
  UpdateType,
  User,
  Wish,
  WishCreateInput,
  WishState,
} from 'types'

import {
  db,
  getNotificationCollectionReference,
  getUpdatesCollectionReference,
  getUserRef,
  getWishCollectionReference,
  getWishRef,
} from '.'

export const createWish = async (
  user: User,
  values: WishCreateInput,
): Promise<void> => {
  const collectionRef = getWishCollectionReference()
  const updatesRef = getUpdatesCollectionReference()
  const userRef = getUserRef(user.id)

  const wish = await addDoc(collectionRef, {
    ...values,
    createdAt: serverTimestamp(),
    userId: user.id,
  })

  await updateDoc(userRef, {
    wishesIds: arrayUnion(wish.id),
  })

  await addDoc(updatesRef, {
    date: Timestamp.now(),
    id: uuid(),
    meta: {
      photoURL: values.photoURL,
      userDisplayName: user.displayName,
      wishId: wish.id,
      wishName: values.name,
    },
    type: UpdateType.ADD_WISH,
    userId: user.id,
  })
}

export const updateWish = async (
  id: string,
  values: Partial<Wish>,
): Promise<void> => {
  const wishRef = getWishRef(id)

  return updateDoc(wishRef, {
    ...values,
    photoURL: values.photoURL || deleteField(),
    updatedAt: Timestamp.now(),
  })
}

export const deleteWish = async (id: string): Promise<void> => {
  const wishRef = getWishRef(id)
  const wish = await getDoc(wishRef)
  const { userId } = wish.data() as Wish

  const batch = writeBatch(db)

  const userRef = getUserRef(userId)

  batch.update(userRef, {
    wishesIds: arrayRemove(id),
  })
  batch.delete(wishRef)

  return batch.commit()
}

export const intentToGive = async (
  from: string,
  to: string,
  wish: string,
): Promise<void> => {
  const senderRef = getUserRef(from)
  const targetRef = getWishRef(wish)

  const batch = writeBatch(db)

  batch.update(senderRef, {
    interactions: arrayUnion({
      id: wish,
      type: InteractionType.GIVE_INTENT,
    }),
  })

  batch.update(targetRef, {
    giver: senderRef,
    state: WishState.SOMEONE_IS_GOING_TO_GIFT,
  })

  return batch.commit()
}

export const removeIntentToGive = async (
  from: string,
  to: string,
  wish: string,
): Promise<void> => {
  const senderRef = getUserRef(from)
  const targetRef = getWishRef(wish)

  const batch = writeBatch(db)

  batch.update(senderRef, {
    interactions: arrayRemove({
      id: wish,
      type: InteractionType.GIVE_INTENT,
    }),
  })

  batch.update(targetRef, {
    giver: deleteField(),
    state: WishState.WANT_IT,
  })

  return batch.commit()
}

export const addView = async (
  wishId: string,
  userId: string,
): Promise<void> => {
  const wishRef = getWishRef(wishId)

  return updateDoc(wishRef, {
    views: arrayUnion(userId),
  })
}

export const setAsOwned = async (
  wish: Wish,
  notification?: Notification,
): Promise<void> => {
  const wishRef = getWishRef(wish.id)
  const updatesRef = getUpdatesCollectionReference()
  const userRef = getUserRef(wish.userId)
  const userDoc = await getDoc(userRef)
  const user = userDoc.data() as User

  const now = Timestamp.now()

  await updateDoc(wishRef, {
    ownedAt: now,
    state: WishState.HAVE_IT,
  })

  await addDoc(updatesRef, {
    date: now,
    id: uuid(),
    meta: {
      photoURL: wish.photoURL,
      userDisplayName: user.displayName,
      wishId: wish.id,
      wishName: wish.name,
    },
    type: UpdateType.OWN_WISH,
    userId: wish.userId,
  })

  if (notification) {
    const notificationsRef = getNotificationCollectionReference()
    await addDoc(notificationsRef, notification)
  }
}
