import { useTranslation } from 'react-i18next'
import { RiArrowGoBackFill } from 'react-icons/ri'

import { NavLink } from 'components/NavLink'

export const BreadcrumbFriend = (): JSX.Element => {
  const { t } = useTranslation()

  return (
    <NavLink icon={<RiArrowGoBackFill />} maxW="auto" to="/friends">
      {t('friend.back')}
    </NavLink>
  )
}
