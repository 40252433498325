import { ChakraProvider } from '@chakra-ui/react'
import '@fontsource/lato'
import '@fontsource/lobster'
import '@fontsource/poppins'
import { BrowserRouter } from 'react-router-dom'

import { AnimatedRoute } from 'components/Animator/AnimatedRoute'
import { AnimatedSwitch } from 'components/Animator/AnimatedSwitch'
import { BrowserHistoryProvider } from 'components/BrowserHistoryProvider'
import { FullScreenLoader } from 'components/FullScreenLoader'
import { DataContext } from 'context/DataContext'
import { UserContext } from 'context/UserContext'
import { useDataContext } from 'hooks/useDataContext'
import { useUserContext } from 'hooks/useUserContext'
import { Dashboard } from 'screens/dashboard'
import { Login } from 'screens/login'
import { NewUserScreen } from 'screens/onboard/index'
import { theme } from 'styles/theme'

export const App = (): JSX.Element => {
  const { handleLogin, handleLogout, loading, user } = useUserContext()

  const userContextValue = {
    handleLogout,
    loading,
    user,
  }

  const dataContextValue = useDataContext()

  return (
    <BrowserRouter>
      <BrowserHistoryProvider>
        <ChakraProvider resetCSS theme={theme}>
          <DataContext.Provider value={dataContextValue}>
            <UserContext.Provider value={userContextValue}>
              <AnimatedSwitch lock={!loading && Boolean(user)}>
                <AnimatedRoute path={!loading ? '/loading' : undefined}>
                  <FullScreenLoader visible />
                </AnimatedRoute>
                <AnimatedRoute exact path="/login">
                  <Login onLogin={handleLogin} />
                </AnimatedRoute>
                <AnimatedRoute path="/onboard">
                  <NewUserScreen />
                </AnimatedRoute>
                <AnimatedRoute path="/">
                  <Dashboard />
                </AnimatedRoute>
              </AnimatedSwitch>
            </UserContext.Provider>
          </DataContext.Provider>
        </ChakraProvider>
      </BrowserHistoryProvider>
    </BrowserRouter>
  )
}
