import { useColorModeValue } from '@chakra-ui/color-mode'
import { VStack } from '@chakra-ui/layout'
import { Radio } from '@chakra-ui/radio'

export const CustomRadio = ({
  children,
  value,
}: {
  children: React.ReactNode
  value: string
}): JSX.Element => {
  return (
    <Radio
      _checked={{ backgroundColor: useColorModeValue('gray.200', 'gray.600') }}
      borderRadius={8}
      colorScheme="primary"
      flex={1}
      maxW="max-content"
      sx={{
        '.chakra-radio__control': { display: 'none' },
        '.chakra-radio__label': { margin: 0 },
      }}
      value={value}
    >
      <VStack p={2}>{children}</VStack>
    </Radio>
  )
}
