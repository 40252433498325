import { Button, IconButton } from '@chakra-ui/button'
import { PlusSquareIcon } from '@chakra-ui/icons'
import { Box, Flex, Text, VStack } from '@chakra-ui/layout'
import { Timestamp } from '@firebase/firestore'
import { useTranslation } from 'react-i18next'

import { AnimatedList } from 'components/Animator/AnimatedList'
import { Translate } from 'components/Translate'
import { uuid } from 'lib/uuid'
import { SpecialDate } from 'types'

import { GiftDate } from './GiftDate'

type Props = {
  handleNext: () => void
  handleBack: () => void
  dates: Array<SpecialDate>
  setFieldValue: (name: string, value: unknown) => void
}

export const SpecialDates = ({
  handleNext,
  handleBack,
  dates,
  setFieldValue,
}: Props): JSX.Element => {
  const { t } = useTranslation()
  const handleAddDate = () => {
    setFieldValue('dates', [
      ...dates,
      {
        date: Timestamp.now(),
        id: uuid(),
        name: '',
        repeat: true,
      },
    ])
  }

  const handleRemove = (id: string) => {
    setFieldValue(
      'dates',
      dates.filter((specialDate) => specialDate.id !== id),
    )
  }

  return (
    <>
      <Text color="primary.300">
        <Translate i18nKey="new_user.dates.body" />
      </Text>
      <VStack spacing={0}>
        <AnimatedList items={dates}>
          {(date, index) => (
            <GiftDate
              index={index}
              onRemove={handleRemove}
              setFieldValue={setFieldValue}
              specialDate={date as SpecialDate}
            />
          )}
        </AnimatedList>
        <Box alignSelf="end" p={2}>
          <IconButton
            aria-label="add"
            icon={<PlusSquareIcon />}
            onClick={handleAddDate}
            size="sm"
          >
            {t('add')}
          </IconButton>
        </Box>
      </VStack>
      <Flex justifyContent="space-between">
        <Button colorScheme="secondary" onClick={handleBack}>
          {t('back')}
        </Button>
        <Button colorScheme="primary" onClick={handleNext}>
          {t('next')}
        </Button>
      </Flex>
    </>
  )
}
