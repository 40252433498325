import { Button } from '@chakra-ui/button'
import { useColorModeValue } from '@chakra-ui/color-mode'
import { FormControl, FormLabel } from '@chakra-ui/form-control'
import { useDisclosure } from '@chakra-ui/hooks'
import { EditIcon } from '@chakra-ui/icons'
import { Input } from '@chakra-ui/input'
import { HStack, Text, VStack } from '@chakra-ui/layout'
import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/modal'
import { Avatar, SkeletonCircle, Tooltip } from '@chakra-ui/react'
import { useFormik } from 'formik'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { AiFillPicture } from 'react-icons/ai'
import { FaSave, FaUserAlt } from 'react-icons/fa'
import { TiLocation } from 'react-icons/ti'

import { useToast } from 'hooks/useToast'
import { deletePhoto } from 'services/firebase/files'
import { updateUser } from 'services/firebase/user'
import { User } from 'types'

import { FormControlDates } from './FormControlDates'
import { FormControlDetails } from './FormControlDetails'
import { InputImage } from './InputImage'

type Props = {
  user: User
}

export const ModalEditUser = ({ user }: Props): JSX.Element => {
  const { t } = useTranslation()
  const toast = useToast()
  const { isOpen, onOpen, onClose } = useDisclosure()
  const [loading, setLoading] = useState(false)

  const handleOpen = () => {
    onOpen()
  }

  const handleClose = () => {
    onClose()
  }

  const { handleSubmit, handleChange, setFieldValue, values } = useFormik({
    initialValues: {
      dates: user.dates,
      details: user.details,
      displayName: user.displayName,
      location: user.location,
      photoURL: user.photoURL,
    },
    onSubmit: async (values) => {
      try {
        setLoading(true)

        if (
          values.photoURL !== user.photoURL &&
          values.photoURL === user.ssoPhotoURL
        ) {
          await deletePhoto(user.photoURL)
        }

        await updateUser(user.id, values)

        toast.success(t('modal_edit_profile.toast_submit_success'))
        setLoading(false)
        handleClose()
      } catch (e) {
        setLoading(false)
        toast.error(t('modal_edit_profile.toast_submit_error'))
      }
    },
  })

  const revertToDefaultPhoto = () => {
    setFieldValue('photoURL', user.ssoPhotoURL)
  }

  const showRevertButton =
    Boolean(user.ssoPhotoURL) && values.photoURL !== user.ssoPhotoURL

  return (
    <>
      <Button
        colorScheme="primary"
        justifyContent="space-between"
        leftIcon={<EditIcon />}
        onClick={handleOpen}
        size="sm"
        w="100%"
      >
        <Text flex={1}>{t('modal_edit_profile.open')}</Text>
      </Button>

      <Modal isOpen={isOpen} onClose={handleClose}>
        <ModalOverlay />
        <ModalContent
          mb={[0, '3.75em']}
          minHeight={['100vh', 'auto']}
          mt={[0, '3.75em']}
        >
          <ModalHeader
            color={useColorModeValue('primary.400', 'primary.200')}
            fontFamily="heading"
            fontWeight={100}
          >
            {t('modal_edit_profile.heading')}
          </ModalHeader>
          <ModalCloseButton />
          <form onSubmit={handleSubmit}>
            <ModalBody>
              <VStack mt={4} spacing={6}>
                <FormControl id="photoURL">
                  <HStack
                    as={FormLabel}
                    color={useColorModeValue('primary.600', 'primary.100')}
                    pb={2}
                  >
                    <AiFillPicture />
                    <Text>{t('avatar')}</Text>
                  </HStack>
                  <HStack>
                    <InputImage name="photoURL" setFieldValue={setFieldValue}>
                      {(handleUpload, loading) => (
                        <SkeletonCircle isLoaded={!loading} size="s">
                          <Tooltip
                            label={t('modal_edit_profile.change_photo_url')}
                            placement="right"
                          >
                            <Avatar
                              onClick={handleUpload}
                              size="xl"
                              src={values.photoURL}
                            ></Avatar>
                          </Tooltip>
                        </SkeletonCircle>
                      )}
                    </InputImage>
                    {showRevertButton && (
                      <Button onClick={revertToDefaultPhoto}>
                        <Avatar
                          mr={2}
                          size="sm"
                          src={user.ssoPhotoURL}
                        ></Avatar>
                        {t('modal_edit_profile.revert_photo_url')}
                      </Button>
                    )}
                  </HStack>
                </FormControl>

                <FormControl id="displayName">
                  <HStack
                    as={FormLabel}
                    color={useColorModeValue('primary.600', 'primary.100')}
                  >
                    <FaUserAlt />
                    <Text>{t('display_name')}</Text>
                  </HStack>
                  <Input
                    onChange={handleChange}
                    type="text"
                    value={values.displayName}
                  />
                </FormControl>

                <FormControl id="location">
                  <HStack
                    as={FormLabel}
                    color={useColorModeValue('primary.600', 'primary.100')}
                  >
                    <TiLocation />
                    <Text>{t('location')}</Text>
                  </HStack>
                  <Input
                    onChange={handleChange}
                    type="text"
                    value={values.location}
                  />
                </FormControl>

                <FormControlDates
                  dates={values.dates || []}
                  setFieldValue={setFieldValue}
                />

                <FormControlDetails
                  details={values.details || []}
                  setFieldValue={setFieldValue}
                />
              </VStack>
            </ModalBody>
            <ModalFooter>
              <Button
                isLoading={loading}
                rightIcon={<FaSave />}
                type="submit"
                variant="primary"
              >
                {t('save')}
              </Button>
            </ModalFooter>
          </form>
        </ModalContent>
      </Modal>
    </>
  )
}
