import { Button } from '@chakra-ui/button'
import { Heading, VStack } from '@chakra-ui/layout'
import {
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/modal'
import { useTranslation } from 'react-i18next'

import { SpecialDateWithUsers } from 'types'

import { CardFriend } from './CardFriend'

type Props = {
  onClose: () => void
  specialDate?: SpecialDateWithUsers
}

export const ModalDateFriend = ({
  specialDate,
  onClose,
}: Props): JSX.Element => {
  const { t } = useTranslation()
  const isOpen = Boolean(specialDate)

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          {specialDate?.date && t('date.short', { date: specialDate?.date })}
          {' - '}
          {specialDate?.name}
        </ModalHeader>
        <ModalBody>
          <VStack>
            <Heading fontWeight="light" size="xs">
              {t('modal_date_friend.heading')}
            </Heading>
            {specialDate?.users.map((user) => {
              return <CardFriend key={user.id} user={user} />
            })}
          </VStack>
        </ModalBody>
        <ModalFooter>
          <Button colorScheme="secondary" onClick={onClose}>
            {t('close')}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}
