import { useBreakpointValue } from '@chakra-ui/media-query'
import { Box, Flex, HStack, useColorModeValue } from '@chakra-ui/react'

import { Notifications } from '../Notifications'

import { HamburguerMenu } from './HamburgerMenu'
import { StickyMenu } from './StickyMenu'
import { UserMenu } from './UserMenu'

enum menus {
  HAMBURGER,
  INLINE,
}

const renderMenu = (menuMode: number) => {
  const MenuComponent =
    menuMode === menus.HAMBURGER ? HamburguerMenu : StickyMenu

  return (
    <HStack>
      <MenuComponent />
    </HStack>
  )
}

export const NavBar = (): JSX.Element => {
  const menuMode: menus =
    useBreakpointValue({
      base: menus.HAMBURGER,
      md: menus.INLINE,
    }) || menus.HAMBURGER

  return (
    <Box
      bg={useColorModeValue('gray.100', 'gray.900')}
      position="sticky"
      px={4}
      top={0}
      w="100%"
      zIndex={10}
    >
      <Flex alignItems={'center'} h={16} justifyContent={'space-between'}>
        {renderMenu(menuMode)}

        <Flex alignItems={'center'} flex={0} gridGap={4}>
          <Notifications />
          <UserMenu />
        </Flex>
      </Flex>
    </Box>
  )
}
