import { Box, HStack, IconButton, Image, Tooltip } from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'
import { BiImageAdd } from 'react-icons/bi'
import { GrRevert } from 'react-icons/gr'

import { coordinatesToStyle } from 'lib/style'
import { DEFAULT_WISH_IMAGE } from 'constantValues'
import { Coordinates } from 'types'

import { ImagePositionEditor } from './ImagePositionEditor'
import { InputImage } from './InputImage'

type Props = {
  photoPosition: Coordinates
  photoURL?: string
  defaultPhotoURL?: string
  setFieldValue: (name: string, value: unknown) => void
  uploadDisabled?: boolean
  loading?: boolean
}

export const ImageEditor = ({
  photoPosition,
  photoURL,
  defaultPhotoURL,
  setFieldValue,
  uploadDisabled,
  loading,
}: Props): JSX.Element => {
  const { t } = useTranslation()

  const revert = () => {
    setFieldValue('photoURL', defaultPhotoURL)
  }

  return (
    <InputImage name="photoURL" setFieldValue={setFieldValue}>
      {(handleUpload, uploading) => (
        <Box position="relative" w="100%">
          <Image
            borderRadius={8}
            h="200"
            objectFit="cover"
            src={photoURL || DEFAULT_WISH_IMAGE}
            style={{
              objectPosition: coordinatesToStyle(photoPosition),
            }}
            w="100%"
          />
          <HStack bottom={0} p={2} position="absolute">
            <Tooltip label={t('image_editor.upload')}>
              <IconButton
                aria-label={t('image_editor.upload')}
                disabled={uploading || uploadDisabled}
                icon={<BiImageAdd />}
                isLoading={loading || uploading}
                onClick={handleUpload}
                variant="secondary"
              />
            </Tooltip>
            <ImagePositionEditor
              defaultPosition={photoPosition}
              disabled={!photoURL || loading || uploading}
              onSetPosition={(position) => {
                setFieldValue('photoPosition', position)
              }}
              photoURL={photoURL || DEFAULT_WISH_IMAGE}
            />
            <Tooltip label={t('image_editor.revert')}>
              <IconButton
                aria-label={t('image_position_editor.tooltip')}
                disabled={
                  uploadDisabled ||
                  defaultPhotoURL === photoURL ||
                  loading ||
                  uploading
                }
                icon={<GrRevert />}
                onClick={revert}
                variant="secondary"
              />
            </Tooltip>
          </HStack>
        </Box>
      )}
    </InputImage>
  )
}
