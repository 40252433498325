import { extendTheme } from '@chakra-ui/react'
import convert from 'color-convert'

type HueSat = [number, number]

const primary: HueSat = [238, 42]
const secondary: HueSat = [43, 86]

const buildColorGrade = (base: HueSat): Record<string, string> => {
  return Array(9)
    .fill(null)
    .reduce((acc, el, i) => {
      return {
        ...acc,
        [(i + 1) * 100]: `#${convert.hsl.hex([...base, (9 - i) * 10])}`,
      }
    }, {})
}

const colors = {
  primary: buildColorGrade(primary),
  secondary: buildColorGrade(secondary),
}

export const theme = extendTheme({
  colors,
  components: {
    Button: {
      variants: {
        gray: (props: { colorMode: string }) => ({
          _hover: {
            bg: props.colorMode === 'dark' ? 'gray.500' : 'gray.300',
          },
          bg: props.colorMode === 'dark' ? 'gray.700' : 'gray.100',
          color: props.colorMode === 'dark' ? 'gray.100' : 'gray.700',
        }),
        primary: (props: { colorMode: string }) => ({
          _hover: {
            bg: props.colorMode === 'dark' ? 'primary.100' : 'primary.300',
            color: props.colorMode === 'dark' ? 'gray.700' : 'white',
          },
          bg: props.colorMode === 'dark' ? 'primary.300' : 'primary.100',
          color: 'gray.700',
        }),
        secondary: (props: { colorMode: string }) => ({
          _hover: {
            bg: props.colorMode === 'dark' ? 'secondary.100' : 'secondary.700',
          },
          bg: props.colorMode === 'dark' ? 'secondary.300' : 'secondary.100',
          color: 'gray.700',
        }),
      },
    },
  },
  config: {
    initialColorMode: 'dark',
    useSystemColorMode: false,
  },
  fonts: {
    body: 'Lato',
    heading: 'Poppins',
    script: 'Lobster',
  },
})
