import {
  collection,
  CollectionReference,
  doc,
  DocumentData,
  DocumentReference,
  setDoc
} from '@firebase/firestore'
import { initializeApp } from 'firebase/app'
import {
  getAuth,
  GoogleAuthProvider,
  signInWithPopup,
  signOut,
  User
} from 'firebase/auth'
import {
  getFirestore,
  limit,
  orderBy,
  OrderByDirection,
  Query as FSQuery,
  query,
  where,
  WhereFilterOp
} from 'firebase/firestore'
import { getFunctions, httpsCallable } from 'firebase/functions'
import { getMessaging, getToken } from 'firebase/messaging'
import { getStorage } from 'firebase/storage'

import { uuid } from 'lib/uuid'

import { CacheQuery } from '../../lib/cache'

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  authDomain: 'wanthat-frbosquet.firebaseapp.com',
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
  measurementId: 'G-4RD0KTZMT8',
  messagingSenderId: '258643681934',
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: 'wanthat-frbosquet.appspot.com',
}

export const firebaseApp = initializeApp(firebaseConfig)
export const googleAuthProvider = new GoogleAuthProvider()
const messaging = getMessaging(firebaseApp)
const auth = getAuth()
export const storage = getStorage(firebaseApp)

const functions = getFunctions(firebaseApp)
export const db = getFirestore()

// if (process.env.NODE_ENV === 'development') {
//   connectFunctionsEmulator(functions, 'localhost', 5001)
//   connectFirestoreEmulator(db, 'localhost', 8080)
// }

export const signinWithGoogle = async (): Promise<User | null> => {
  try {
    const result = await signInWithPopup(auth, googleAuthProvider)
    return result.user
  } catch (e) {
    alert('Unable to login')
    return null
  }
}

export const logOut = async (): Promise<void> => {
  return signOut(auth)
}

export const unfurl = httpsCallable<
  string,
  {
    title: string
    description: string
    price: number
    photoURL: string
  }
>(functions, 'unfurl')

// Collections
enum Collections {
  USER = 'user',
  WISH = 'wish',
  UPDATE = 'update',
  NOTIFICATION = 'notification',
  NOTIFICATION_TOKEN = 'notification_token',
  FILE = 'file',
}

export const getGenericRef = (
  collName: string,
  id: string,
): DocumentReference<DocumentData> => doc(db, collName, id)

export const getGenericCollectionRef = (
  collName: string,
): CollectionReference => collection(db, collName)

export const getUserRef = (id: string): DocumentReference<DocumentData> =>
  getGenericRef(Collections.USER, id)

export const getUserCollectionReference = (): CollectionReference =>
  getGenericCollectionRef(Collections.USER)

export const getWishRef = (id: string): DocumentReference<DocumentData> =>
  getGenericRef(Collections.WISH, id)

export const getWishCollectionReference = (): CollectionReference =>
  getGenericCollectionRef(Collections.WISH)

export const getUpdatesCollectionReference = (): CollectionReference =>
  getGenericCollectionRef(Collections.UPDATE)

export const getNotificationRef = (
  id: string,
): DocumentReference<DocumentData> =>
  getGenericRef(Collections.NOTIFICATION, id)

export const getNotificationCollectionReference = (): CollectionReference =>
  getGenericCollectionRef(Collections.NOTIFICATION)

export const getNotificationNewRef = (): DocumentReference<DocumentData> =>
  getGenericRef(Collections.NOTIFICATION, uuid())

export const getFileRef = (id: string): DocumentReference<DocumentData> =>
  getGenericRef(Collections.FILE, id)

export const requestPushNotificationPermission = async (): Promise<void> => {
  const userId = auth.currentUser?.uid

  if (!userId)
    throw new Error(
      'Trying to request permission to a unregistered user. This is no-go. Check requestPushNotificationPermission method',
    )

  try {
    const token = await getToken(messaging, {
      vapidKey: process.env.REACT_APP_FIREBASE_VAPID_KEY,
    })

    const docRef = getGenericRef(Collections.NOTIFICATION_TOKEN, userId)

    await setDoc(docRef, { token })
  } catch (e) {
    // eslint-disable-next-line
    console.error('token register failed', e)
  }
}

export const buildQueryRef = (
  collection: string,
  queryParams: CacheQuery[],
): FSQuery<DocumentData> => {
  const reference = getGenericCollectionRef(collection)

  const q = query(
    reference,
    ...queryParams.map(({ type, meta }) => {
      switch (type) {
        case 'where': {
          const { field, op, value } = meta as {
            field: string
            op: WhereFilterOp
            value: string | string[]
          }

          return where(field, op, value)
        }
        case 'orderBy': {
          const { field, direction } = meta as {
            field: string
            direction: OrderByDirection
          }

          return orderBy(field, direction)
        }
        case 'limit': {
          const { value } = meta as {
            value: number
          }

          return limit(value)
        }
        default:
          throw new Error('unhandled query operation. Check ' + type)
      }
    }),
  )

  return q
}
